@charset 'UTF-8';

.form-notes-container {
  font-weight: 500;
  margin-bottom: 2em;
}



.form-container {
  border-top: $base-form-border;
  display: block;
  @include media(md){
    display: flex;
  }
  &._last {
    border-bottom: $base-form-border;
  }
}

// フォームの項目名表示
.form-title {
  background-color: $form-title-background-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0;
  width: 100%;
  &.vertical-top {
    @include media(md){
      justify-content: flex-start;
    }
  }

  @include media(md){
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1rem;
    width: 14rem;
  }
  @include media(lg){
    padding: 2rem 1.5rem;
    width: 20rem;
  }

  // 項目名
  .title {
    color: $base;
    font-size: 1rem;
    font-weight: 700;
    @include media(md){
      flex: 0 0 19rem;
    }
  }

  // 必須と任意
  .optional,
  .required {
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    padding: .4em .6em calc( .4em - 1px );
    @include media(md){
      margin-top: .4em;
    }
  }
  // 任意
  .optional {
    background: $third;
    color: $white;
  }
  // 必須
  .required {
    background: $accent;
    color: $white;
  }
}

// フォーム入力表示
.form-value {
  background-color: $form-value-background-color;
  padding: 1rem;
  @include media(md){
    border-left: none;
    flex: 1;
    padding: 2rem 1rem;
  }
  @include media(lg){
    padding: 2rem 1.5rem;
  }
}
