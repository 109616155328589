@charset 'UTF-8';

.local-content-wrapper{
  width: 100%;
  flex: 1 0 auto;
  @include make-container;
  display: flex;
  flex-direction: column;
  .local-content-container{
    flex: 1 0 auto;
    margin: 5rem 0;
    @include media(md){
      margin: 5.75rem 0;
      padding: 5rem;
      background: $white;
      box-shadow: 0px 0px 2px 2px rgba( #000, 0.2 );
    }
  }
  .exception-content-container{
    flex: 1 0 auto;
    margin: 5rem 0;
    @include media(md){
      margin: 5.75rem 0;
      background: $white;
      box-shadow: 0px 0px 2px 2px rgba( #000, 0.2 );
    }
    .exception-content-inner{
      margin-top: map-get( $section-block-gutters, xs );
      @include media(md){
        margin-top: map-get( $section-block-gutters, sm );
      }
      @include media(md){
        margin-top: 0;
        padding: 5rem;
      }
    }
  }
}

.local-visual-wrapper{
  width: 100%;
  padding-top: calc( 100% * 400 / 1040);
  position: relative;
  background-size: cover;
  background-position: bottom;
  .local-visual-content{
    font-size: 1.75em;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.1875em;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    @include media(md){
      font-size: 2.25em;
    }
    .subtitle{
      font-size: 1.25rem;
      letter-spacing: 0;
      display: block;
      margin-bottom: 0.25rem;
    }
  }
}

.local-sub-visual-wrapper{
  width: 100%;
  padding-top: calc( 100% * 240 / 1040);
  position: relative;
  background-size: cover;
  background-position: bottom;
  .local-sub-visual-content{
    font-size: 1.75em;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.1875em;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    @include media(md){
      font-size: 2.25em;
    }
    .subtitle{
      font-size: 1.25rem;
      letter-spacing: 0;
      display: block;
      margin-bottom: 0.25rem;
    }
  }
}

.sanka-visual-wrapper{
  width: 100%;
  padding-top: 240px;
  position: relative;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
  @include media(md){
    padding-top: calc( 100% * 480 / 1200);
  }
  .sanka-visual-content{
    font-family: $notosans;
    position: absolute;
    top: 55%;
    left: 4rem;
    transform: translateY(-55%);
    z-index: 3;
    margin-bottom: 0;
    @include media(md){
      left: 6rem;
    }
    @include media(lg){
      left: 7rem;
    }
    .title{
      font-size: 1.75rem;
      color: $white;
      text-shadow: 1px 1px 0.1em darken($sanka-base, 10%);
      font-weight: 700;
      @include media(md){
        font-size: 2rem;
      }
      @include media(lg){
        font-size: 2.25rem;
      }
    }
    .subtitle{
      font-size: 1rem;
      display: block;
      margin-bottom: 0.1875rem;
      color: $white;
      font-weight: 700;
      text-shadow: 1px 1px 0.1em darken($sanka-base, 10%);
      @include media(md){
        font-size: 1rem;
      }
      @include media(lg){
        font-size: 1.25rem;
      }
    }
    .en{
      font-size: 0.75rem;
      display: block;
      margin-top: 0.5rem;
      color: $accent;
      font-weight: 500;
      @include media(md){
        font-size: 0.75rem;
      }
      @include media(lg){
        font-size: 0.875rem;
      }
    }
  }
  &::before{
    content: '';
    width: calc(510px * 0.7);
    height: calc(382px * 0.7);
    background-image: url('../../../../images/visitor/medical-treatment/department/sanka/sanka-visual-bg.svg');
    background-size: contain; 
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 1;
    display: block;
    transform: translateY(-50%);
    @include media(md){
      width: calc(510px * 0.8);
      height: calc(382px * 0.8);
      left: 2rem;
    }
    @include media(lg){
      width: 510px;
      height: 382px;
      left: 3rem;
    }
  }
  &::after{
    content: '';
    width: calc(1200px * 0.5);
    height: calc(480px * 0.5);
    background-image: url('../../../../images/visitor/medical-treatment/department/sanka/sanka-visual-border.svg');
    background-size: contain; 
    background-repeat: no-repeat;
    position: absolute;
    display: block;
    bottom: -5rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    display: block;
    @include media(md){
      width: calc(1200px * 0.8);
      height: calc(480px * 0.8);
    }
    @include media(lg){
      width: 1200px;
      height: 480px;
    }
  }
}

.sanka-sub-visual-wrapper{
  width: 100%;
  padding-top: 160px;
  position: relative;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
  @include media(md){
    padding-top: calc( 100% * 320 / 1200);
  }
  .sanka-sub-visual-content{
    font-family: $notosans;
    position: absolute;
    top: 55%;
    left: 4rem;
    transform: translateY(-55%);
    z-index: 3;
    margin-bottom: 0;
    @include media(md){
      left: 6rem;
    }
    @include media(lg){
      left: 7rem;
    }
    .title{
      font-size: 1.75rem;
      color: $white;
      text-shadow: 1px 1px 0.1em darken($sanka-base, 10%);
      font-weight: 700;
      @include media(md){
        font-size: 2rem;
      }
      @include media(lg){
        font-size: 2.25rem;
      }
    }
    .subtitle{
      font-size: 1rem;
      display: block;
      margin-bottom: 0.1875rem;
      color: $white;
      font-weight: 700;
      text-shadow: 1px 1px 0.1em darken($sanka-base, 10%);
      @include media(md){
        font-size: 1rem;
      }
      @include media(lg){
        font-size: 1.25rem;
      }
    }
    .en{
      font-size: 0.75rem;
      display: block;
      margin-top: 0.5rem;
      color: $accent;
      font-weight: 500;
      @include media(md){
        font-size: 0.75rem;
      }
      @include media(lg){
        font-size: 0.875rem;
      }
    }
  }
  &::before{
    content: '';
    width: calc(510px * 0.7);
    height: calc(382px * 0.7);
    background-image: url('../../../../images/visitor/medical-treatment/department/sanka/sanka-visual-bg.svg');
    background-size: contain; 
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 1;
    display: block;
    transform: translateY(-50%);
    @include media(md){
      width: calc(510px * 0.8);
      height: calc(382px * 0.8);
      left: 2rem;
    }
    @include media(lg){
      width: 510px;
      height: 382px;
      left: 3rem;
    }
  }
  &::after{
    content: '';
    width: calc(1200px * 0.5);
    height: calc(480px * 0.5);
    background-image: url('../../../../images/visitor/medical-treatment/department/sanka/sanka-visual-border.svg');
    background-size: contain; 
    background-repeat: no-repeat;
    position: absolute;
    display: block;
    bottom: -5rem;
    left: 0;
    z-index: 2;
    display: block;
    @include media(md){
      width: calc(1200px * 0.8);
      height: calc(480px * 0.8);
      bottom: -8rem;
    }
    @include media(lg){
      width: 1200px;
      height: 480px;
      bottom: -9rem;
    }
  }
}

.sanka-room-wrapper{
  background: $sanka-second;
  border: solid 2px darken($sanka-second, 5%);
  padding: 1.75rem;
  height: 100%;
  .sanka-room-heading{
    color: $sanka-first;
    font-weight: bold;
    font-size: 1.25rem;
  }
  .sanka-room-desc{
    background: $white;
    padding: 1rem;
  }
  .sanka-room-label{
    background: $sanka-first;
    color: $white;
    padding: 0.5rem;
    line-height: 1;
    font-size: 0.875rem;
    display: inline-block;
  }
}

.sanka-comment{
  background: $sanka-second;
  border: solid 2px darken($sanka-second, 5%);
  padding: 1.75rem;
  height: 100%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a.sanka-link{
  color: $accent;
  &:hover{
    color: darken( $accent, 10% );
  }
}

.index-page-list{
  display: flex;
  flex-wrap: wrap;
  margin: calc( var(--grid-gutter) * -.5 );
  li{
    width: 100%;
    padding: calc( var(--grid-gutter) * .5 );
    @include media(md){
      width: 50%;
    }
    @include media(lg){
      width: 33.33333%;
    }
  }
}