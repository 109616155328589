@charset 'UTF-8';

.bnr-list-wrapper{
  border: solid 3px $first;
  padding: 1rem;
  @include media(md){
    padding: 2rem;
  }
  .bnr-list-container{
    margin: -0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    @include media(md){
      flex-direction: row;
    }
    @include media(lg){
      //flex-wrap: nowrap;
    }
    .bnr-list-item{
      padding: 0.5rem;
      width: 100%;
      @include media(md){
        width: 50%;
      }
      // @include media(lg){
      //   width: 33.33333%;
      // }
      @include media(xl){
        width: 20%;
      }
      .bnr-link{
        background: $third;
        color: #fff;
        display: flex;
        font-weight: bold;
        text-align: center;
        line-height: 1.2;
        padding: 1em;
        font-size: 1.25rem;
        font-family: $notosans;
        font-weight: 700;
        //letter-spacing: 0.0625em;
        position: relative;
        height: 100%;
        align-items: center;
        justify-content: center;
        border: solid 2px $third;
        transition: background $base-transition;
        @include media(xl){
          flex-direction: column;
        }
        &::after{
          content: '';
          background-image: set-svg($arrow, $white);
          width: 1.5em;
          height: 1.5em;
          display: inline-block;
          position: absolute;
          right: 0.375em;
          bottom: 0;
          color: $white;
        }
        &:hover{
          @include media(md){
            background: #fff;
            color: $third;
            &::after{
              background-image: set-svg($arrow, $third);
            }
          }
        }
      }
      .sub-title{
        font-size: 0.75em;
        display: inline-block;
        margin-right: 0.75rem;
      }
    }
  }
}
