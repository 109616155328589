@charset 'UTF-8';



// input & textarea
//
.border-input {
  background-color: $input-background-color;
  border: $border-animation-thickness solid $input-background-color;
  box-shadow: none;
  color: $gray-dark;
  font-size: $form-font-size;
  line-height: 1;
  padding: 1rem;
  width: 100%;
  transition: border-color $base-transition, background-color $base-transition;

  // プレースホルダー
  &::placeholder {
    color: $placeholder-color;
    font-size: 1rem;
    transition: color $base-transition;
  }
  &::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $placeholder-color;
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }
  &::-ms-expand {
    background: $white;
    border: 0;
  }

  // File input
  &[type="file"] {
    overflow: hidden;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &::file-selector-button {
    background-color: $gray-100;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: $gray-dark;
    margin: (-$input-y-padding) (-$input-x-padding);
    margin-inline-end: $input-x-padding;
    padding: $input-y-padding $input-x-padding;
    pointer-events: none;
  }
  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $gray-200;
  }

  &::-webkit-file-upload-button {
    background-color: $gray-100;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: $gray-dark;
    margin: (-$input-y-padding) (-$input-x-padding);
    margin-inline-end: $input-x-padding;
    padding: $input-y-padding $input-x-padding;
    pointer-events: none;
  }
  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $gray-200;
  }

  // フォーカス
  &:focus {
    background-color: $white;
    border-color: $form-border-color;
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }

  // エラー
  &.error {
    border-color: $form-error-color;
  }

  // テキストエリア
  &.textarea {
    border: $border-animation-thickness solid transparent;
    line-height: 1.6;
    padding: 1em;
    &:focus {
      background: $white;
      border-color: $form-border-color;
      outline: 0;
      &::placeholder {
        color: transparent;
      }
    }
    &.error {
      border-color: $form-error-color;
    }
  }
}



#{$form-name-class}{

  // textarea
  //
  textarea {
    resize: vertical;
    height: auto;
    width: 100%;
  }



  // select
  //
  select {
    width: 100%;
    &::-moz-focus-inner {
      border: 0;
    }
  }

  .select {
    display: inline-block;
    position: relative;
    select {
      background: $input-background-color;
      border: 1px solid transparent;
      color: inherit;
      cursor: pointer;
      display: block;
      font-size: 1rem;
      line-height: 1;
      padding: .875rem 32px .875rem .75rem;
      position: relative;
      outline: none;
      overflow: hidden;
    }
    // 矢印
    &:after {
      border: 2px solid $base;
      border-right: 0;
      border-top: 0;
      content: '';
      display: block;
      margin-top: -3px;
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: rotate(-45deg) translateY(-50%);
      width: 8px;
      height: 8px;
    }
  }



  // radio button & checkbox
  //

  input[type='checkbox'],
  input[type='radio'] {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    margin: 0;
    outline: none;
  }

  .checkbox-group,
  .radio-group {
    display: inline-block;
    // 横並び
    &.row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-top: ( -$form-radio-checked-gutter-y );
      @include media(md){
        margin-top: 0;
      }
      .checkbox-item,
      .radio-item {
        margin-right: 1.5em;
        padding-top: $form-radio-checked-gutter-y;
      }
    }
  }

  .radio-group {
    label {
      display: inline-block;
      font-size: $form-font-size;
      line-height: 1.2;
      min-height: $form-radio-size;
      position: relative;
      padding-left: 22px;
      margin-bottom: 0;
      cursor: pointer;
      &:before, &:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        transition: all .2s ease;
        transition-property: transform, border-color;
      }
      &:before {
        left: 0;
        top: 0;
        width: $form-radio-size;
        height: $form-radio-size;
        border: 1px solid $form-radio-border-color;
      }
      &:after {
        top: math.div($form-radio-size, 2) - math.div($form-radio-checked-size, 2);
        left: math.div($form-radio-size, 2) - math.div($form-radio-checked-size, 2);
        width: $form-radio-checked-size;
        height: $form-radio-checked-size;
        transform: scale(0);
        background: $form-radio-checked-color;
      }
    }

    input[type="radio"] {
      &:checked + label:before {
        border-color: $form-radio-checked-color;
      }
      &:checked + label:after {
        transform: scale(1);
      }
    }
  }

  .checkbox-group {
    input[type="checkbox"] + label {
      cursor: pointer;
      display: inline-block;
      position: relative;
      user-select: none;
      line-height: 18px;
      padding-left: 24px;
      &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 2px solid rgba( $black, 0.54 );
        transition: all .12s, border-color .08s;
        position: absolute;
        left: 0;
        animation: rippleRev 0.2s linear forwards;
      }
      &:after {
        content: '';
        display: inline-block;
        height: 12px;
        border: 1px solid $base;
        position: absolute;
        left: 6px;
        top: 1px;
        width: 7px;
        border-radius: 0;
        border-top-color: transparent;
        border-left-color: transparent;
        border-width: 2px;
        transform: rotate(45deg);
        opacity: 0;
        transition: all 0.12s;
      }
    }

    // checked
    input[type="checkbox"]:checked + label {
      color: $gray-dark;
      &:after {
        content: '';
        opacity: 1;
      }
      &::before{
        content: '';
        animation: ripple 0.2s linear forwards;
        border: 2px solid $base;
      }
    }
  }
}



// readonly 属性の INPUT 要素
.input-readonly {
  background: $white;
  box-shadow: none;
  color: $gray-dark;
  font-size: $form-font-size;
  font-weight: 700;
  line-height: 1;
  padding: .5em 0;
  width: 100%;
  // フォーカス
  &:focus {
    background: $white;
    border-color: $base;
    box-shadow: none;
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
}
