@charset 'UTF-8';

.topics-panel-wrapper{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  flex-wrap: wrap;
  padding: 1rem;
  @include media(md){
    padding: 0;
  }
  @include media(xl){
    flex-wrap: nowrap;
  }
  .topics-panel-content{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 50%;
    padding-bottom: 50%;
    display: block;
    position: relative;
    @include media(md){
      width: 33.33333%;
      padding-bottom: 400px;
    }
    @include media(xl){
      width: 100%;
    }
    &::after{
      content: '';
      width: 100%;
      height: 100%;
      background: rgba( $base, 0.7 );
      position: absolute;
      left: 0;
      bottom: 0;
      transition: height 0.3s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      @include media(md){
        height: 172px;
      }
    }
    &:hover{
      @include media(md){
        &::after{
          height: 100%;
        }
        .link{
          &::after{
            left: 0 !important;
            top: 0 !important;
          }
        }
      }
    }
    .topics-panel-item{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @include media(md){
        height: 172px;
      }
      .upper{
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .lower{
        height: 40%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 0.75rem;
      }
      .heading{
        color: #fff;
        font-size: 1.35714285714rem;
        font-family: $notosans;
        font-weight: 700;
        letter-spacing: 0.0625em;
        line-height: 1.4;
        text-align: center;
        @include media(md){
          font-size: 1.5rem;
        }
        .sub-text{
          font-size: 1rem;
          display: block;
          line-height: 1.2;
          @include media(md){
            font-size: 1.125rem;
          }
        }
      }
      .link{
        padding: 0.375rem 1rem;
        display: inline-block;
        color: $base-text-color;
        font-size: 11px;
        font-family: $notosans;
        font-weight: 700;
        letter-spacing: 0.0625em;
        border: solid 1px $base-text-color;
        position: relative;
        @include media(md){
          font-size: 0.75rem;
        }
        &::after{
          content: '';
          width: 100%;
          height: 100%;
          background: #fff;
          display: block;
          position: absolute;
          left: 0.25rem;
          top: 0.25rem;
          z-index: -1;
          transition: top $base-transition, left $base-transition;
        }
      }
    }
  }
}