@charset 'UTF-8';

// variable
$pagination-active-class: '.current';



.pagination-wrapper {
  width: auto;
}
