@charset 'UTF-8';

// アイコンのサイズ
$base-icon-size: 1em;

// アイコンの空き
$base-icon-gutter: .142857rem;



// 種類を追加したい場合：'使用する名前': svg の変数名;
//
$svg-icon-types: (
  'arrow'             : $arrow,
  'chev-down'         : $chev-down,
  'arrow-down'        : $arrow-down,
  'arrow-right'       : $arrow-right,
  'circle-arrow-right': $circle-arrow-right,
  'plus'              : $plus,
  'pdf'               : $pdf,
  'document'          : $document,
  'phone'             : $phone,
  'smart-phone'       : $smart-phone,
  'circle-phone'      : $circle-phone,
  'fax'               : $fax,
  'mail'              : $mail,
  'new-window'        : $new-window,
  'calendar'          : $calendar,
  'search'            : $search
) !default;

@mixin svg-icon-default($icon: $arrow-right, $color: $base)
{
  background-image: set-svg($icon, $color);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  display: inline-block;
  width: $base-icon-size;
  height: $base-icon-size;
}



// icon
// Font Awesome
//
$icon-font-family: 'Font Awesome 5 Free';



// 種類を追加したい場合：'使用する名前': Fontawesomeのコード;
// Fontawesome のアイコンが Solid の場合 font-weight: 900; 、Regular の場合 font-weight: 400;
//
$icon-types: (
  'arrows'   : '\f061',
  'back'     : '\f100',
  'check'    : '\f00c',
  'clock'    : '\f017',
  'duplicate': '\f0c5',
  'edit'     : '\f304',
  'eye'      : '\f06e',
  'eye-slash': '\f070',
  'fax'      : '\f1ac',
  'hatena'   : '\f059',
  'info'     : '\f05a',
  'link'     : '\f0c1',
  'mail'     : '\f0e0',
  'new'      : '\f067',
  'pdf'      : '\f1c1',
  'plus'     : '\f055',
  'privacy'  : '\f13e',
  'send'     : '\f1d8',
  'tel'      : '\f098',
  'trash'    : '\f2ed',
  'update'   : '\f2f1',
  'outside'  : '\f2d2'
) !default;



// Mixin
@mixin icon-default( $weight: 900, $type: 'arrows' )
{
  font-family: $icon-font-family;
  content: map-get( $icon-types, $type );
  color: inherit;
  display: inline-block;
  font-weight: $weight;
  text-align: center;
}
