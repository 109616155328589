@charset 'UTF-8';

// フォントサイズ
$form-font-size: 16px;

// input の padding
$input-x-padding: 1em;
$input-y-padding: .8em;

// 項目同士の余白
$form-field-gutter-xs: 2em !default;
$form-field-gutter-sm: 2em !default;
$form-field-gutter-md: 2em !default;
$form-field-gutter-lg: 2em !default;
$form-field-gutter-xl: 2em !default;

$form-field-gutters: (
  xs: $form-field-gutter-xs,
  sm: $form-field-gutter-sm,
  md: $form-field-gutter-md,
  lg: $form-field-gutter-lg,
  xl: $form-field-gutter-xl
) !default;

// form-grid 内のカラム数
$form-grid-columns: 12 !default;

// グリッド要素同士の空白
$form-grid-gutter-xs: 4px !default;
$form-grid-gutter-sm: 4px !default;
$form-grid-gutter-md: 4px !default;
$form-grid-gutter-lg: 4px !default;
$form-grid-gutter-xl: 4px !default;

$form-grid-gutters: (
  xs: $form-grid-gutter-xs,
  sm: $form-grid-gutter-sm,
  md: $form-grid-gutter-md,
  lg: $form-grid-gutter-lg,
  xl: $form-grid-gutter-xl
) !default;

// ボタン
$form-btn-border: 2px;
$form-btn-border-radius: 2em;
$form-btn-font-size: 16px;
$form-btn-height: 44px;
$form-btn-padding: 16px;
$form-btn-gutter: 8px;

// INPUT のバックグラウンドカラー
$input-background-color: #f2f5f9 !default;

// ボーダーの太さ
$border-animation-thickness: 1px;

// ボーダーの色
$form-border-color: #B2C4DE;

// ボーダーのアニメーションで変化するボーダー色
$border-animation-color: $base;



// border-form
//
// タイトルのバックグラウンドカラー
$form-title-background-color: $white;

// 項目のバックグラウンドカラー
$form-value-background-color: $white;

// コンテナのボーダー
$base-form-border: 1px solid #999;

// コンテナの背景色
$base-form-background-color: $white;

// チェックボックス & ラジオボタン
$form-radio-checked-color: $base;
$form-radio-border-color: $form-border-color;
$form-radio-size: 18px;
$form-radio-checked-size: 12px;
$form-radio-ripple-size: 12px;

// チェックボックス & ラジオボタンの上下のマージン
$form-radio-checked-gutter-y: 10px;

// form 要素をフォーカスしたときに付与するクラス名
$form-focus-in-class-name: '.focus-in';
$form-focus-fixed-class-name: '.focus-fixed';

// プレースホルダーの色
$placeholder-color: #ced4da;

// プレースホルダーの変化する色
$placeholder-after-color: #339966;

// エラーの色
$form-error-color: $red;
$form-error-background-color: #FFDFD8;
