@charset 'UTF-8';



h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  line-height: $heading-line-height;
  margin-top: 0;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: $h1-font-size;
  margin-bottom: $h1-margin-bottom;
  position: relative;
  &.headline {
    display: inline-flex;
    align-items: center;
    padding-bottom: 1rem;
    font-family: $notosans;
    font-weight: 700;
    font-size: 1.5625rem;
    letter-spacing: 0.1875em;
    margin-bottom: 2rem;
    &::before {
      content: '';
      background-color: $accent;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 4.571428rem;
      height: 3px;
    }
    span {
      color: #999;
      display: inline-block;
      font-size: .875rem;
      font-weight: normal;
      margin-left: 1rem;
      letter-spacing: 0.0625em;
    }
  }
  &.heading{
    position: relative;
    padding-bottom: 1.25rem;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.0625em;
    &::after{
      content: '';
      width: 4.875rem;
      height: 3px;
      position: absolute;
      display: inline-block;
      left: 0;
      bottom: 0;
      background-color: $accent;
    }
  }
}

h2 {
  font-size: $h2-font-size;
  margin-bottom: $h2-margin-bottom;
  &.heading{
    padding: 1.125rem 0;
    border-top: solid 1px #999999;
    border-bottom: solid 1px #999999;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.0625em;
  }
}

h3 {
  font-size: $h3-font-size;
  margin-bottom: $h3-margin-bottom;
  &.heading{
    padding: 0.75rem 1rem;
    background-color: #D9E1EE;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.0625em;
    &.second{
      background-color: rgba($second, 0.35);
    }
  }
}

h4 {
  font-size: $h4-font-size;
  margin-bottom: $h4-margin-bottom;
  &.heading{
    font-family: $notosans;
    font-weight: 900;
    letter-spacing: 0.0625em;
    color: $accent;
  }
}

h5 {
  font-size: $h5-font-size;
  margin-bottom: $h5-margin-bottom;
}

h6 {
  font-size: $h6-font-size;
  margin-bottom: $h6-margin-bottom;
}



// Paragraph
.lead {
  font-size: inherit;
  text-rendering: optimizeLegibility;

  @each $key, $value in $breakpoints {
    @include media($key, true){
      letter-spacing: map-get($lead-letter-spacing, $key);
      line-height: map-get($lead-line-height, $key);
    }
  }

  // .lead spacer
  & + & {
    @each $key, $value in $breakpoints {
      @include media($key, true){
        margin-top: map-get($lead-top-spacer, $key);
      }
    }
  }
}



// フォントファミリーを日本語に限定する（※）
.mbf {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}



// code
pre {
  background: $gray-100;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
  code {
    background: transparent;
    color: $gray-700;
    font-family: 'SourceHanCodeJP-Regular', monospace;
    font-size: .8em;
    font-weight: bold;
    vertical-align: initial;
  }
}


