@charset 'UTF-8';

// Variables

// メインメニューの背景色
$gnav-local-link-background-color: #f7f6f7;

// 子メニューの背景色
$child-background-color: #d9e1ee;


// モバイルメニュー（3つのメニュー）
.global-nav-mobile {
  display: block;
  //padding-top: $header-height-xs + $header-border-weight;
  @include media(lg){
    display: none;
  }
  // navigation
  ul.global-nav {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  // メインメニュー
  li.gnav-parent {
    flex: 0 0 math.percentage( math.div(1, 3) );
  }

  a.gnav-local-link {
    background-color: $gnav-local-link-background-color;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: .857142rem 0;
    position: relative;
    height: 100%;
    justify-content: center;
    // border
    &::after {
      content: '';
      background-color: $accent;
      display: block;
      width: 3.428574rem;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    // テキスト
    span.headline {
      color: $gray-dark;
      display: block;
      font-size: .714285rem;
      font-weight: 700;
      font-family: $notosans;
      letter-spacing: 0.0625em;
      margin-bottom: 0.25rem;
      @include media(md){
        font-size: .93333333rem;
      }
    }
    span.en {
      color: $gray-500;
      font-size: .642857142857143rem;
      font-family: $notosans;
      text-align: center;
      line-height: 1.2;
      @include media(md){
        letter-spacing: 0.0625em;
      }
    }
  }
}

#{ $menu-open-class }{
  overflow-y: hidden;
  height: 100%;
}

// PCメニュー & モバイルメニュー
.global-nav-integration {
  position: fixed;
  padding-top: $header-height-xs + $header-border-weight;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: $child-background-color;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-y: auto;
  #{$menu-open-class} & {
    transition: transform 0.3s cubic-bezier(0.8, 0, 0.2, 1) 0s, animation 0.3s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  }
  #{$menu-close-class} & {
    transition: transform 0.3s cubic-bezier(0.8, 0, 0.2, 1) 0s, animation 0.3s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  }
  @include media(md){
   padding-top: $header-height-md + $header-border-weight;
  }
  @include media(lg){
    padding: 0;
    display: block;
    position: relative;
    top: 0;
    background-color: transparent;
    height: auto;
    overflow-y: initial;
    min-height: auto;
  }
  //左から出るメニュー
  &.from-left{
    transform: translateX(-100vw);
    #{$menu-open-class} & {
      animation: left-open;
      transform: translateX(0);
    }
    #{$menu-close-class} & {
      animation: left-close;
      transform: translateX(-100vw);
    }
    @include media(lg){
      transform: translateX(0);
    }
  }
  //右から出るメニュー
  &.from-right{
    transform: translateX(100vw);
    #{$menu-open-class} & {
      animation: right-open;
      transform: translateX(0);
    }
    #{$menu-close-class} & {
      animation: right-close;
      transform: translateX(100vw);
    }
    @include media(lg){
      transform: translateX(0);
    }
  }
  //上から出るメニュー
  &.from-top{
    transform: translateY(-100vh);
    #{$menu-open-class} & {
      animation: top-open;
      transform: translateY(0);
    }
    #{$menu-close-class} & {
      animation: top-close;
      transform: translateY(-100vh);
    }
    @include media(lg){
      transform: translateY(0);
    }
  }
  //下から出るメニュー
  &.from-bottom{
    transform: translateY(100vh);
    #{$menu-open-class} & {
      animation: bottom-open;
      transform: translateY(0);
    }
    #{$menu-close-class} & {
      animation: bottom-close;
      transform: translateY(100vh);
    }
    @include media(lg){
      transform: translateY(0);
    }
  }

  // wrapper
  .global-nav-wrapper {
    background: transparent;
  }

  // container
  .global-nav-container {
    margin: 0 auto;
    width: 100%;
  }

  // navigation
  ul.global-nav {
    @include media(lg){
      display: flex;
      justify-content: space-between;
      position: relative;
    }
  }

  // メインメニュー
  li.gnav-parent {
    @include media(lg){
      flex: 0 0 math.percentage( math.div(1, 3) );
    }
  }

  .gnav-local-link {
    background-color: $child-background-color;
    display: flex;
    flex-flow: column wrap;
    align-items: baseline;
    padding: 1rem;
    position: relative;
    &::after {
      content: '';
      background-image: set-svg($arrow-down, $base);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
    }
    @include media(lg){
      background-color: $gnav-local-link-background-color;
      align-items: center;
      justify-content: center;
      transition: background-color $base-transition 0.2s;
      // border
      &::after {
        content: '';
        background-color: $accent;
        background-image: none;
        display: block;
        width: 4.875rem;
        height: 3px;
        position: absolute;
        top: unset;
        bottom: 0;
        right: unset;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    // テキスト
    span.headline {
      color: $base;
      display: block;
      font-size: .857142rem;
      font-weight: 700;
      font-family: $notosans;
      letter-spacing: 0.0625em;
      @include media(lg){
        font-size: 1.75rem;
        color: $gray-dark;
        //transition: color $base-transition;
      }
    }
    span.en {
      display: none;
      @include media(lg){
        color: #999999;
        display: block;
        font-size: 1.25em;
        font-family: $notosans;
        letter-spacing: 0.0625em;
        //margin-left: 1.375rem;
        //transition: color $base-transition;
      }
    }
  }

  .hover {
    a.gnav-local-link{
      background-color: $child-background-color;
      span.headline {
        color: $base;
      }
      span.en {
        color: $base;
      }
    }
  }

  // チャイルドメニュー
  .gnav-child-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height $base-transition;
    @include media(lg){
      position: absolute;
      left: 0;
      width: 100%;
      background: $child-background-color;
      transition: max-height $base-transition 0.2s;
    }
  }

  .gnav-child-container {
    //background-color: $gnav-local-link-background-color;
    //padding: 0.5rem 0;
    @include media(lg){
      background-color: transparent;
      display: flex;
      margin: 0 auto;
      padding: 1rem map-get( $base-side-padding, lg );
      max-width: get_max_width( lg );
    }
    @include media(xl){
      margin: 0 auto;
      padding: 1rem map-get( $base-side-padding, xl );
      max-width: get_max_width( xl );
    }
  }

  .gnav-child-inner{
    @include media(lg){
      padding: 1rem;
      &.col-12{
        width: calc(100% * (12 / 12));
      }
      &.col-11{
        width: calc(100% * (11 / 12));
      }
      &.col-10{
        width: calc(100% * (10 / 12));
      }
      &.col-9{
        width: calc(100% * (9 / 12));
      }
      &.col-8{
        width: calc(100% * (8 / 12));
      }
      &.col-7{
        width: calc(100% * (7 / 12));
      }
      &.col-6{
        width: calc(100% * (6 / 12));
      }
      &.col-5{
        width: calc(100% * (5 / 12));
      }
      &.col-4{
        width: calc(100% * (4 / 12));
      }
      &.col-3{
        width: calc(100% * (3 / 12));
      }
      &.col-2{
        width: calc(100% * (2 / 12));
      }
      &.col-1{
        width: calc(100% * (1 / 12));
      }
    }
  }

  .gnav-child-item{
    max-height: 0;
    overflow: hidden;
    transition: max-height $base-transition;
    @include media(lg){
      max-height: none;
      overflow: auto;
      transition: none;
      display: flex;
      margin: -1rem;
    }
  }

  .gnav-child-list{
    @include media(lg){
      padding: 1rem;
      flex: 1;
    }
    br{
      display: none;
      @include media(lg){
        display: block;
      }
    }
  }

  .gnav-child-link {
    background-color: $gnav-local-link-background-color;
    color: $gray-dark;
    display: block;
    font-size: .857142rem;
    padding: 0.875rem 0.875rem 0.875rem 2rem;
    position: relative;
    &::after {
      content: '';
      background-image: set-svg($arrow, $base);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      @include media(lg){
        content: none;
      }
    }
  }

  

  .gnav-child-headline{
    background-color: darken($child-background-color, 5%);
    color: $gray-dark;
    display: block;
    font-size: .857142rem;
    padding: 0.875rem 0.875rem 0.875rem 1.5rem;
    position: relative;
    font-weight: 500;
    font-family: $notosans;
    letter-spacing: 0.0625em;
    @include media(lg){
      color: $base;
      border-bottom: solid 1px $base;
      margin-bottom: 1.5rem;
      font-size: 1.25rem;
      font-weight: bold;
      background-color: transparent;
      padding: 0.25rem 0;
    }

    &::after {
      content: '';
      background-image: set-svg($arrow-down, $base);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      @include media(lg){
        content: none;
      }
    }
  }

  .gnav-child-link{
    font-weight: 500;
    font-family: $notosans;
    letter-spacing: 0.0625em;
    line-height: 1.4;
    @include media(lg){
      background-color: transparent;
      padding: 0.25rem 0 0.25rem 1.125rem;
      font-size: 1rem;
      &::before{
        content: '';
        background-image: set-svg($arrow-right, $base);
        background-repeat: no-repeat;
        background-size: contain;
        width: 0.75rem;
        height: 0.75rem;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 55%;
        transform: translateY(-55%);
      }
    }
  }

} // .global-nav-integration