@charset 'UTF-8';

// TAB
//
.symptoms-tab-wrapper {
  width: 100%;
}

ul.symptoms-tab-label {
  border-bottom: 2px solid $base;
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding: 0 1rem;
  li {
    padding: 0 .5rem;
    width: 50%;
    max-width: 50%;
    position: relative;
    @include media(md) {
      padding: 0 1rem;
    }
  }
}

.symptoms-tab-label-item {
  background-color: $gray-100;
  cursor: pointer;
  color: $gray-500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  padding: .75rem 0 calc(.75rem - 2px);
  height: 100%;
  text-align: center;
  transition: background-color .25s;
  border-radius: 5px 5px 0 0;
  @include media(md) {
    font-size: 1rem;
  }
  &:hover {
    @include media(lg) {
      background-color: $gray-200;
      color: $gray-600;
    }
  }
  &#{$tab-current-class-name} {
    background-color: $base;
    color: $white;
    font-weight: 700;
  }
}

.symptoms-tab-contents {
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.symptoms-tab-contents-item {
  animation: show .4s linear 0s;
  display: none;
  padding: 2em;

  &#{$tab-current-view-class-name} {
    display: block;
  }
}



// LAYOUT
//
.symptoms-grid-container {
  display: block;
  position: relative;
  @include media(lg){
    display: flex;
    margin: -1rem;
  }
}

.symptoms-grid-columns {
  animation: show $base-transition;
  display: none;
  &.active {
    display: block;
  }
  @include media(lg){
    display: block;
    flex: 0 0 50%;
    padding: 1rem;
  }
}



// 症状から診療科を選択してください
.symptoms-heading {
  background: #dfecff;
  padding: 0.5em 1em;
  font-weight: 700;
  text-align: center;
}

.symptoms-contents {
  display: flex;
  align-items: center;
  padding: 1.5em 0;
  flex-direction: column;
  flex-wrap: wrap;
  @include media(xl) {
    margin: 0 -.5em;
    flex-direction: row;
  }
  & + & {
    border-top: solid 1px #ccc;
  }
}

.symptoms-items {
  padding: .5em 0;
  width: 100%;
  @include media(xl) {
    padding: 0 0.5em;
    width: 50%;
    max-width: 50%;
  }
}



// ボタン
.symptoms-btn {
  background-color: $base;
  border: 2px solid $base;
  color: $white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.0625em;
  padding: .75rem 1rem;
  transition: background-color $base-transition;
  &::after {
    content: '';
    background-image: set-svg($arrow, $white);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: inline-block;
    margin-left: .5rem;
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem;
  }
  &:hover {
    background-color: $white;
    color: $base;
    &::after {
      background-image: set-svg($arrow, $base);
    }
  }
  & + & {
    margin-top: 1rem;
  }
}

// 部位の選択にもどる
.symptoms-back-btn-wrapper {
  margin: 4rem auto 0;
  width: 260px;
  @include media(lg) {
    display: none;
  }
}

.symptoms-back-btn {
  background-color: $white;
  border: 2px solid $base;
  color: $base;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.0625em;
  padding: .75rem 1rem;
  transition: background-color $base-transition;
  width: 100%;
  &::before {
    content: '';
    background-image: set-svg($arrow-left, $base);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    background-color: $base;
    color: $white;
    &::before {
      background-image: set-svg($arrow-left, $white);
    }
  }
}


.attention-inner {
  background: $gray-200;
  border: solid 2px $gray-400;
  font-size: 0.875em;
  padding: 0.5em 1em;
}

.symptoms-text {
  display: flex;
  align-items: center;
  font-size: .875rem;
  line-height: 1.4;
  position: relative;
  &::before {
    content: '';
    background-image: set-svg($check-circle, $accent);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: inline-block;
    flex: 0 0 1rem;
    margin-right: .25rem;
    width: 1rem;
    height: 1rem;
  }
  & + & {
    margin-top: .5rem;
  }
}



// COMPONENT
//
.symptoms-step {
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}

.symptoms-image-wrapper {
  display: block;
  margin: 40px auto;
  padding-bottom: 50px;
  position: relative;
  width: 100%;
  max-width: 288px;
  @include media(md){
    max-width: 360px;
  }
  @include media(lg){
    max-width: 380px;
  }
}

.symptoms-image {
  display: block;
  margin: auto;
  width: 220px;
  @include media(xl){
    width: 280px;
  }
}



// 部位（ラジオボタン）
.symptoms-image-wrapper {
  input[type='checkbox'],
  input[type='radio'] {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    margin: 0;
    position: absolute;
    outline: none;
  }
}

.symptoms-radio {
  $border-color: #d9e1ee;
  $bg-color: #f2f5f9;

  position: absolute;
  label {
    background-color: $bg-color;
    border: 1px solid $border-color;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    padding: .5em 1em;
    transition: background-color $base-transition;
    &:hover {
      border-color: $gray-300;
    }
  }

  input[type="radio"] {
    &:checked + label {
      background-color: $base;
      color: $white;
    }
  }
}



// 位置
[class*='symptoms-position-']{
  &::before {
    content: '';
    background-color: $gray-dark;
    display: inline-block;
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    content: '';
    background-color: $white;
    border: 1px solid $gray-dark;
    border-radius: 50%;
    display: inline-block;
    width: 9px;
    height: 9px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

$sym-position-xs: -82px;
$sym-position-md: -118px;
$sym-position-lg: -122px;

.adult{

  // 頭部
  .symptoms-position-1 {
    top: -8px;
    &::before {
      right: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        right: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        right: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      right: -90px;
      @include media(md){
        right: -123px;
      }
      @include media(lg){
        right: -129px;
      }
    }
  }

  // 顔面
  .symptoms-position-2 {
    top: 22px;
    right: 0;
    @include media(xl){
      top: 30px;
    }
    &::before {
      left: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        left: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        left: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      left: -90px;
      @include media(md){
        left: -122px;
      }
      @include media(lg){
        left: -128px;
      }
    }
  }

  // 頸部
  .symptoms-position-3 {
    top: 50px;
    @include media(xl){
      top: 65px;
    }
    &::before {
      right: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        right: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        right: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      right: -90px;
      @include media(md){
        right: -123px;
      }
      @include media(lg){
        right: -129px;
      }
    }
  }

  // 胸部
  .symptoms-position-4 {
    top: 92px;
    right: 0;
    @include media(xl){
      top: 118px;
    }
    &::before {
      left: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        left: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        left: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      left: -90px;
      @include media(md){
        left: -122px;
      }
      @include media(lg){
        left: -128px;
      }
    }
  }

  // 腹部
  .symptoms-position-5 {
    top: 157px;
    @include media(xl){
      top: 198px;
    }
    &::before {
      right: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        right: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        right: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      right: -90px;
      @include media(md){
        right: -123px;
      }
      @include media(lg){
        right: -129px;
      }
    }
  }

  // 下腹部
  .symptoms-position-6 {
    top: 206px;
    @include media(xl){
      top: 264px;
    }
    &::before {
      right: -74px;
      width: 74px;
      @include media(md){
        right: -100px;
        width: 100px;
      }
      @include media(lg){
        right: -106px;
        width: 106px;
      }
    }
    &::after {
      right: -78px;
      @include media(md){
        right: -108px;
      }
      @include media(lg){
        right: -113px;
      }
    }
  }

  // 腰・背部
  .symptoms-position-7 {
    top: 188px;
    right: 0;
    @include media(xl){
      top: 232px;
    }
    &::before {
      left: -24px;
      width: 24px;
      @include media(md){
        left: -54px;
        width: 54px;
      }
    }
    &::after {
      left: -30px;
      @include media(md){
        left: -62px;
      }
    }
  }

  // 下肢
  .symptoms-position-8 {
    top: 294px;
    @include media(xl){
      top: 376px;
    }
    &::before {
      right: -60px;
      width: 60px;
      @include media(md){
        right: -90px;
        width: 90px;
      }
      @include media(lg){
        right: -94px;
        width: 94px;
      }
    }
    &::after {
      right: -60px;
      @include media(md){
        right: -96px;
      }
      @include media(lg){
        right: -102px;
      }
      @include media(xl){
        right: -96px;
      }
    }
  }

  // 全身
  .symptoms-position-9 {
    bottom: 0;
    right: 52%;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }

  // その他
  .symptoms-position-10 {
    bottom: 0;
    left: 52%;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }

}

.child{

  // 頭部
  .symptoms-position-1 {
    top: -8px;
    &::before {
      right: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        right: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        right: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      right: -90px;
      @include media(md){
        right: -123px;
      }
      @include media(lg){
        right: -129px;
      }
    }
  }

  // 顔面
  .symptoms-position-2 {
    top: 22px;
    right: 0;
    @include media(xl){
      top: 30px;
    }
    &::before {
      left: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        left: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        left: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      left: -90px;
      @include media(md){
        left: -122px;
      }
      @include media(lg){
        left: -128px;
      }
    }
  }

  // 頸部
  .symptoms-position-3 {
    top: 55px;
    @include media(xl){
      top: 73px;
    }
    &::before {
      right: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        right: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        right: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      right: -90px;
      @include media(md){
        right: -123px;
      }
      @include media(lg){
        right: -129px;
      }
    }
  }

  // 胸部
  .symptoms-position-4 {
    top: 92px;
    right: 0;
    @include media(xl){
      top: 118px;
    }
    &::before {
      left: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        left: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        left: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      left: -90px;
      @include media(md){
        left: -122px;
      }
      @include media(lg){
        left: -128px;
      }
    }
  }

  // 腹部
  .symptoms-position-5 {
    top: 140px;
    @include media(xl){
      top: 180px;
    }
    &::before {
      right: $sym-position-xs;
      width: $sym-position-xs * -1;
      @include media(md){
        right: $sym-position-md;
        width: $sym-position-md * -1;
      }
      @include media(lg){
        right: $sym-position-lg;
        width: $sym-position-lg * -1;
      }
    }
    &::after {
      right: -90px;
      @include media(md){
        right: -123px;
      }
      @include media(lg){
        right: -129px;
      }
    }
  }

  // 下腹部
  .symptoms-position-6 {
    top: 180px;
    @include media(xl){
      top: 230px;
    }
    &::before {
      right: -74px;
      width: 74px;
      @include media(md){
        right: -100px;
        width: 100px;
      }
      @include media(lg){
        right: -106px;
        width: 106px;
      }
    }
    &::after {
      right: -78px;
      @include media(md){
        right: -108px;
      }
      @include media(lg){
        right: -113px;
      }
    }
  }

  // 腰・背部
  .symptoms-position-7 {
    top: 155px;
    right: 0;
    @include media(xl){
      top: 205px;
    }
    &::before {
      left: -24px;
      width: 24px;
      @include media(md){
        left: -54px;
        width: 54px;
      }
    }
    &::after {
      left: -30px;
      @include media(md){
        left: -62px;
      }
    }
  }

  // 下肢
  .symptoms-position-8 {
    top: 294px;
    @include media(xl){
      top: 376px;
    }
    &::before {
      right: -60px;
      width: 60px;
      @include media(md){
        right: -90px;
        width: 90px;
      }
      @include media(lg){
        right: -94px;
        width: 94px;
      }
    }
    &::after {
      right: -96px;
      @include media(lg){
        right: -102px;
      }
      @include media(xl){
        right: -96px;
      }
    }
  }

  // 全身
  .symptoms-position-9 {
    bottom: 0;
    right: 52%;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }

  // その他
  .symptoms-position-10 {
    bottom: 0;
    left: 52%;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }

}