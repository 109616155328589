@charset 'UTF-8';

// form class name
$form-name-class: '.border-form';



// iOSのフォーカス時のズームも無効にする
//
#{$form-name-class} {
  input[type='text'],
  input[type='radio'],
  input[type='checkbox'],
  textarea {
    font-size: $form-font-size;
  }
}

// Layout
@import 'form-layout';

// Component
@import 'form-component';

// フォームで使用するボタン
@import 'form-btn';

// 確認画面用のモーダルウインドウ
@import 'form-modal';

// フォームのフォーカス時のギミック
@import 'form-gimmick';
