@charset 'UTF-8';

// Color system

// Main color
//
$base:   #133a8b !default;
$accent: #dc3785 !default;
$first:  #374149 !default;
$second: #F2ACC6 !default;
$third:  #3FA9F5 !default;
$fourth: #7ac943 !default;
$five:   #F9931A !default;

$colors: (
  'base':   $base,
  'accent': $accent,
  'first':  $first,
  'second': $second,
  'third':  $third,
  'fourth': $fourth,
  'five':   $five
) !default;



// Gray color
//
$white:     #fff    !default;
$gray-100:  #f8f9fa !default;
$gray-200:  #e9ecef !default;
$gray-300:  #dee2e6 !default;
$gray-400:  #ced4da !default;
$gray-500:  #adb5bd !default;
$gray-600:  #6c757d !default;
$gray-700:  #495057 !default;
$gray-800:  #343a40 !default;
$gray-900:  #212529 !default;
$gray-dark: #333    !default;
$black:     #000    !default;

$grays: (
  'gray-100':  $gray-100,
  'gray-200':  $gray-200,
  'gray-300':  $gray-300,
  'gray-400':  $gray-400,
  'gray-500':  $gray-500,
  'gray-600':  $gray-600,
  'gray-700':  $gray-700,
  'gray-800':  $gray-800,
  'gray-900':  $gray-900,
  'gray-dark': $gray-dark,
  'white':     $white,
  'black':     $black
) !default;



// Common color
//
$red:     #b0120a !default;
$yellow:  #fff000 !default;
$orange:  #f18f2c !default;
$green:   #63a764 !default;
$blue:    #005fcc !default;
$pink:    #ff0066 !default;
$purple:  #6f42c1 !default;
$cyan:    #17a2b8 !default;

$commons: (
  'red':    $red,
  'yellow': $yellow,
  'orange': $orange,
  'green':  $green,
  'blue':   $blue,
  'pink':   $pink,
  'purple': $purple,
  'cyan':   $cyan
) !default;


// カラーの配列を作る
//
// グレースケールと汎用カラーの配列
$commons-colors: map-merge(
  $grays,
  $commons
);

// テーマカラーを含めたすべての配列
$all-colors: map-merge(
  $colors,
  $commons-colors
);

$sanka-base: $second;
$sanka-first: #a1865f;
$sanka-second: #fffaea;