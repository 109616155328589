@charset 'UTF-8';

// copyright-wrapper
//
.copyright-wrapper {
  background: $first;
  flex: none;
  padding: 0 0 .6rem;
  position: relative;
  width: 100%;
  z-index: $z-index-footer;
}



// copyright-container
//
.copyright-container {
  @include make-container;
}



// copuright
//
.copyright {
  color: $white;
  font-size: 75%;
  text-align: center;
}
