@charset 'UTF-8';

:root {
  // root font size
  @each $key, $size in $font-size {
    @include media($key, true){
      --root-font-size: #{$size};
    }
  }

  // grid
  @each $key, $value in $breakpoints {
    @include media($key, true){
      --grid-gutter: #{map-get($grid-gutters, $key)};
      --grid-gutter-narrow: #{map-get($grid-gutters-narrow, $key)};
      --grid-gutter-wide: #{map-get($grid-gutters-wide, $key)};
      --form-grid-gutter: #{map-get($form-grid-gutters, $key)};
    }
  }
}



html {
  font-size: var(--root-font-size);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  overflow: auto;
  height: -webkit-fill-available;
}



body {
  background: $base-background-color;
  color: $base-text-color;
  font-family: $base-font-family;
  font-size: 1rem;
  line-height: $base-line-height;
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  // フォントレンダリング設定
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  // フォントレンダリング設定 - Retina Display
  @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), ( min-resolution: 2dppx ) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}



// Links
a {
  color: $base-link-color;
  text-decoration: $base-link-decoration;
  &:hover {
    color: $base-link-hover-color;
    text-decoration: $base-link-hover-decoration;
  }
}



// Images
img {
  border-style: none;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}



// Horizontal line
hr {
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: 1rem 0;
  width: 100%;
  height: 0;
}
