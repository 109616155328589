@charset 'UTF-8';



// grid
.grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc( var(--grid-gutter) * -.5 );
  margin-right: calc( var(--grid-gutter) * -.5 );
  margin-left: calc( var(--grid-gutter) * -.5 );
  margin-bottom: calc( var(--grid-gutter) * -.5 );
  // 隣接
  & + & {
    margin-top: calc( var(--grid-gutter) * .5 );
  }
  // col
  .col {
    padding: calc( var(--grid-gutter) * .5 );
  }
  *{
    box-sizing: contant-box;
  }
}
// grid
.grid-narrow {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc( var(--grid-gutter-narrow) * -.5 );
  margin-right: calc( var(--grid-gutter-narrow) * -.5 );
  margin-left: calc( var(--grid-gutter-narrow) * -.5 );
  margin-bottom: calc( var(--grid-gutter-narrow) * -.5 );
  // 隣接
  & + & {
    margin-top: calc( var(--grid-gutter-narrow) * .5 );
  }
  // col
  .col {
    padding: calc( var(--grid-gutter-narrow) * .5 );
  }
  *{
    box-sizing: contant-box;
  }
}
// grid
.grid-wide {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc( var(--grid-gutter-wide) * -.5 );
  margin-right: calc( var(--grid-gutter-wide) * -.5 );
  margin-left: calc( var(--grid-gutter-wide) * -.5 );
  margin-bottom: calc( var(--grid-gutter-wide) * -.5 );
  // 隣接
  & + & {
    margin-top: calc( var(--grid-gutter-wide) * .5 );
  }
  // col
  .col {
    padding: calc( var(--grid-gutter-wide) * .5 );
  }
  *{
    box-sizing: contant-box;
  }
}

// Form grid
.form-grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc( var(--form-grid-gutter) * -.5 );
  margin-right: calc( var(--form-grid-gutter) * -.5 );
  margin-left: calc( var(--form-grid-gutter) * -.5 );
  margin-bottom: calc( var(--form-grid-gutter) * -.5 );
  // 隣接
  & + & {
    margin-top: calc( var(--form-grid-gutter) * .5 );
  }
  // col
  .col {
    padding: calc( var(--form-grid-gutter) * .5 );
  }
}



// item
@each $key, $value in $breakpoints {
  @include media($key, true){
    @for $i from 1 through $grid-columns {
      @include make-grid-items( $key, $i );
    }
  }
}



// 揃える
//
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
// 縦組み
.justify-end-vertical {
  align-content: flex-end;
}

.justify-center-vertical {
  align-content: center;
}

.justify-between-vertical {
  align-content: space-between;
}

.justify-around-vertical {
  align-content: space-around;
}



// 子要素の天地の揃え
// align-items は親要素 align-self は子要素
//
.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}
