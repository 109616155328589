@charset 'UTF-8';

// margin & padding

.m0  { margin         : 0 !important; }
.mt0 { margin-top     : 0 !important; }
.mb0 { margin-bottom  : 0 !important; }
.ml0 { margin-left    : 0 !important; }
.mr0 { margin-right   : 0 !important; }
.p0  { padding        : 0 !important; }
.pt0 { padding-top    : 0 !important; }
.pb0 { padding-bottom : 0 !important; }
.pl0 { padding-left   : 0 !important; }
.pr0 { padding-right  : 0 !important; }

@for $i from 1 through 25 {
  .mt#{$i * 4} {
    margin-top: ( $i * 4 ) + px !important;
    @each $key, $value in $scale {
      @include media($key){
        margin-top: ceil( $i * 4 * $value ) + px !important;
      }
    }
  }

  .mb#{$i * 4} {
    margin-bottom: ( $i * 4 ) + px !important;
    @each $key, $value in $scale {
      @include media($key){
        margin-bottom: ceil( $i * 4 * $value ) + px !important;
      }
    }
  }

  .mr#{$i * 4} {
    margin-right: ( $i * 4 ) + px !important;
    @each $key, $value in $scale {
      @include media($key){
        margin-right: ceil( $i * 4 * $value ) + px !important;
      }
    }
  }

  .ml#{$i * 4} {
    margin-left: ( $i * 4 ) + px !important;
    @each $key, $value in $scale {
      @include media($key){
        margin-left: ceil( $i * 4 * $value ) + px !important;
      }
    }
  }

  .pt#{$i * 4} {
    padding-top: ( $i * 4 ) + px !important;
    @each $key, $value in $scale {
      @include media($key){
        padding-top: ceil( $i * 4 * $value ) + px !important;
      }
    }
  }

  .pb#{$i * 4} {
    padding-bottom: ( $i * 4 ) + px !important;
    @each $key, $value in $scale {
      @include media($key){
        padding-bottom: ceil( $i * 4 * $value ) + px !important;
      }
    }
  }

  .pr#{$i * 4} {
    padding-right: ( $i * 4 ) + px !important;
    @each $key, $value in $scale {
      @include media($key){
        padding-right: ceil( $i * 4 * $value ) + px !important;
      }
    }
  }

  .pl#{$i * 4} {
    padding-left: ( $i * 4 ) + px !important;
    @each $key, $value in $scale {
      @include media($key){
        padding-left: ceil( $i * 4 * $value ) + px !important;
      }
    }
  }
}

// text-align
//
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
  // text-align-last: justify;
  // text-justify: inter-ideograph;
}

// font color
//
@each $name, $color in $all-colors {
  .fc-#{$name} {
    color: $color !important;
  }
}

// font size
//
@each $name, $size in $font-size-percentage {
  .fs-#{$name} {
    font-size: $size !important;
  }
}

// line height
//
@for $i from 10 through 20 {
  .lh-#{$i} {
    line-height: #{$i * 0.1} !important;
  }
}

// background color
//
@each $name, $color in $all-colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}

// width
//
@for $i from 1 through 20 {
  .w#{$i * 5} {
    width: #{$i * 5%} !important;
  }
}

// common
//
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.center-block {
  display: block;
  margin: 0 auto;
}

// webfonts
//
.ff-noto{
  font-family: $notosans;
  letter-spacing: 0.1875em;
}

// font weight
//
.fw-100{
  font-weight: 100;
}

.fw-300{
  font-weight: 300;
}

.fw-400{
  font-weight: 400;
}

.fw-500{
  font-weight: 500;
}

.fw-700{
  font-weight: 700;
}

.fw-900{
  font-weight: 900;
}

.fw-bold{
  font-weight: bold;
}

.fw-normal{
  font-weight: normal;
}

.bg-box{
  background: rgba($base, 0.05);
  padding: 2rem;
  @include media(md){
    padding: 3rem;
  }
  &.second{
    background: rgba($second, 0.15);
  }
  &.narrow{
    padding: 2rem;
  }
}

.caption-wrapper{
  margin-top: 1rem;
}



.author-row{
  display: flex;
  align-items: center;
  .author-dept{
    margin-top: 0.125em;
    & + .author-name{
      margin-left: 1rem;
    }
  }
}
.author-col{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.author-dept{
  color: $gray-600;
  font-size: 0.875rem;
  font-weight: bold;
  @include media(md){
    font-size: 1rem;
  }
}
.author-name{
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  @include media(md){
    font-size: 1.375rem;
  }
}

//マーカー
.marker{
  background: linear-gradient(transparent 75%, #fff799 75%);
}

span.notes {
  display: inline;
  padding-left: 1.2em;
  position: relative;
  &:before {
    font-family: jp;
    content: '※';
    position: absolute;
    left: 0;
  }
}

//PCのみ電話を押せないように
a[href*="tel"]{
  @include media(md) {
    color: $base-text-color;
    text-decoration: none;
    pointer-events: none;
  }
}

.content-center{
  display: flex;
  justify-content: center;
}

  .sm-hide{
    display: none;
    @include media(md){
      display: block;
    }
  }
  .md-hide{
    display: none;
    @include media(lg){
      display: block;
    }
  }

  .bnr-elm{
    box-shadow: 0px 0px 2px 1px rgba( #000, 0.2 );
    transition: opacity $base-transition;
    display: inline-block;
    &:hover{
      opacity: 0.6;
    }
  }