@charset 'UTF-8';

// 休診・代診

.closure-section-container {
  position: relative;
  & + & {
    margin-top: 4rem;
  }
}



ul.closure-item-list {
  display: flex;
  flex-flow: row wrap;
  margin: -8px;
  padding: 0;

  li {
    list-style: none;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding: 8px;
    @include media(md){
      @include make-grid( percentage( math.div(1, 2) ));
    }
    @include media(xl){
      @include make-grid( percentage( math.div(1, 3) ) );
    }
  }
}



.closure-item-container {
  background-color: $white;
  border: 1px solid $gray-300;
  padding: 12px 20px;
  height: 100%;
  &._closure { // 休診
    background-color: #fcf5f8;
    border: 1px solid #f5e1e9;
  }
  &._assist { // 代診
    background-color: #f3f5f9;
    border: 1px solid #dbe1ed;
  }
}



.closure-title {
  //border-radius: 6px;
  color: $white;
  display: block;
  font-size: 1em;
  font-weight: 700;
  padding: 6px 0;
  text-align: center;
  ._closure & { // 休診
    background: $accent;
  }
  ._assist & { // 代診
    background: $base;
  }
  ._change & { // 代診
    background: $first;
  }
}

.closure-date {
  color: $gray-800;
  font-size: 1.21428571428em;
  font-weight: 700;
  padding: 10px 0;
  text-align: center;
}

.closure-timezone {
  margin-bottom: 10px;
  text-align: center;
  [class^='timezone_'] {
    background: lighten($base, 20%);
    color: $white;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-left: .5em;
    padding: .333333em .666666em calc( .333333em - 1px );
  }
  // 午前
  .timezone_0 {
    background-color: #ec83ab;
  }
  // 午後
  .timezone_1 {
    background-color: #60a8ef;
  }
  // 終日
  .timezone_2 {
    background-color: #999999;
  }
}


.closure-name {
  background: $gray-100;
  //border-radius: 4px;
  color: $gray-800;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: .71428571428em;
  padding: .28571428571em;
  text-align: center;
}

.closure-doctor {
  font-size: 1.14285714285em;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}

.closure-comment {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.6;
  margin-top: 1em;
}
