@charset 'UTF-8';

ul.list,
ol.list {
  ul, ol {
    //margin-left: 1em;
  }
  &.li-mb {
    > li:nth-of-type(n+2) {
      margin-top: .8em;
    }
    ul, ol {
      //margin-top: .8em;
    }
  }
  // インデント
  &.indent {
    margin-left: 1em;
    ul, ol {
      margin-top: .8em;
    }
  }
}



// ul の装飾
//
ul.list {
  // dotted
  &.disc {
    list-style: disc outside;
    padding-left: 1.125em;
  }
  // 四角 & 丸
  &.square,
  &.circle{
    > li {
      line-height: inherit;
      padding-left: 1.25em;
      position: relative;
      &:before {
        content: '';
        color: $accent;
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &.square {
    > li {
      &:before {
        content: '■';
      }
    }
  }
  &.circle {
    > li {
      &:before {
        content: '●';
      }
    }
  }
  &.dots {
    > li {
      line-height: inherit;
      padding-left: 1.25em;
      position: relative;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $accent;
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0.625em;
        left: 0.25em;
      }
    }
  }
  &.notes {
    > li {
      line-height: inherit;
      padding-left: 1.25em;
      position: relative;
      &:before {
        font-family: jp;
        content: '※';
        position: absolute;
        left: 0;
      }
    }
  }
}


// ol 数字
//
ol.list {
  &.num {
    list-style: decimal outside;
    padding-left: 1.125em;
    ol {
      list-style: decimal outside;
    }
  }
  &.num-circle {
    > li{
      padding-left: 1.5em;
      position: relative;
      &::before{
        content: '';
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background: $gray-dark;
        display: inline-block;
        position: absolute;
        top: 0.125rem;
        left: 0;
      }
      &:after {
        position: absolute;
        text-align: center;
        width: 1.25rem;
        display: inline-block;
        letter-spacing: -0.125rem;
        padding-right: 0.125rem;
        white-space: nowrap;
        line-height: 1;
        font-weight: bold;
        top: 0.3125rem;
        left: 0;
        font-size: 0.875rem;
        color: $white;
      }
      @for $i from 1 through 100 {
        &:nth-child(#{ $i })::after {
          content: #{'"' + #{$i} + '"'};
        }
      }
    }
  }
  &.num-kakomi {
    > li{
      padding-left: 1.25em;
      position: relative;
      &:before {
        position: absolute;
        left: 0;
      }
      @for $i from 0 through 9 {
        $num: '0' + ( 2460 + $i );
        $result: '\\#{$num}';
        &:nth-child(#{$i + 1})::before {
          content: #{'"' + #{$result} + '"'};
        }
      }
    }
  }
}


// list の横並び
//
.list-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  li {
    &:not(:last-child){
      margin-right: .5em;
    }
  }
}


// dl
//
dl.list {
  dt {
    font-weight: 700;
    &:nth-of-type(n+2) {
      margin-top: 1em; }
  }
  dd {
    line-height: 1.4;
  }
  //横並び定義リスト
  &.horizonal {
    & dt, dd {
      line-height: 1.5;
    }
    & dt {
      float: none;
      font-weight: normal;
      &:not(:first-child) {
        margin-top: 0.5rem;
        @include media(lg) {
          margin-top: 0;
        }
      }
      &:not(:first-of-type) {
        @include media(sm) {
          margin-top: 0.25rem;
        }
      }
      @include media(lg) {
        float: left;
        clear: left;
        margin-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    & dd {
      &:not(:first-child) {
        margin-top: 0.25rem;
        @include media(lg) {
          margin-top: 0;
        }
      }
      &:not(:first-of-type) {
        @include media(sm) {
          margin-top: 0.25rem;
        }
      }
    }
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    @for $i from 1 through 40 {
      &.dt#{$i}{
        & dt{
          @include media(lg) {
            width: #{$i}em;
          }
        }
        & dd {
          @include media(lg) {
            margin-left: #{$i}em;
          }
        }
      }
    }
    &.nowrap dt {
      &:not(:first-child) {
        margin-top: 0;
      }
      &:not(:first-of-type) {
        margin-top: 0.25rem;
      }
      float: left;
      clear: left;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.nowrap dd {
      &:not(:first-child) {
        margin-top: 0;
      }
      &:not(:first-of-type) {
        margin-top: 0.25rem;
      }
    }
    @for $i from 1 through 40 {
      &.nowrap.dt#{$i}{
        & dt{
          width: #{$i}em;
        }
        & dd {
          margin-left: #{$i}em;
        }
      }
    }
  }
}

.flow-list-wrapper{
  .flow-list-item{
    background: #fff;
    & + .flow-list-item{
      position: relative;
      margin-top: 4rem;
      &::after {
        content: "";
        width: 2rem;
        height: 2rem;
        display: inline-block;
        background-image: set-svg($chev-down, $accent);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute; 
        top: -3rem;
        left: 50%;
        transform: translateX(-50%);
        @include media(md) {
          left: 50%;
        }
      }
    }
    div.heading{
      background: rgba($second, 0.35);
      //padding: 0.375rem;
      padding: 0.75rem;
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: 1rem;
      text-align: center;
      letter-spacing: 0.0625em;
    }
    .title{
      font-weight: bold;
      font-size: 1.25rem;
      text-align: center;
    }
    .body{
      border: solid 3px rgba($second, 0.35);
      background: $white;
      padding: 1.5rem;
    }
  }
  &.num{
    .flow-list-item{
      @for $i from 0 through 9 {
        $num: '0' + ( 2460 + $i );
        $result: '\\#{$num}';
        &:nth-child(#{$i + 1}) .heading::before {
          content: #{'"' + #{$result} + '"'};
        }
      }
    }
  }
}

.qa-list{
  margin-top: -0.5rem;
  .question{
    padding-left: 2rem;
    position: relative;
    margin: 0.5rem 0;
    line-height: 1.5rem;
    font-weight: bold;
    &::before{
      content: 'Q';
      line-height: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      color: $white;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      text-align: center;
      font-weight: bold;
    }
    &::after{
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-color: $third;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      border-radius: 0.75rem;
    }
  }
  .answer{
    padding-left: 2rem;
    position: relative;
    margin: 0.5rem 0;
    line-height: 1.5rem;
    &::before{
      content: 'A';
      line-height: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      color: $white;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      text-align: center;
      font-weight: bold;
    }
    &::after{
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-color: $accent;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      border-radius: 0.75rem;
    }
    & + .question{
      margin-top: 1.75rem;
    }
  }
}