@charset 'UTF-8';



// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;



// Container
$container-max-width: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1200px
) !default;



// Base gutter
$base-gutter: 1rem !default;

$base-gutter-xs: $base-gutter !default;
$base-gutter-sm: $base-gutter !default;
$base-gutter-md: $base-gutter !default;
$base-gutter-lg: $base-gutter !default;
$base-gutter-xl: $base-gutter !default;

$base-gutters: (
  xs: $base-gutter-xs,
  sm: $base-gutter-sm,
  md: $base-gutter-md,
  lg: $base-gutter-lg,
  xl: $base-gutter-xl
) !default;



// Base side padding
$base-side-padding-xs: $base-gutter-xs !default;
$base-side-padding-sm: $base-gutter-sm !default;
$base-side-padding-md: $base-gutter-md !default;
$base-side-padding-lg: $base-gutter-lg !default;
$base-side-padding-xl: $base-gutter-xl !default;

$base-side-padding: (
  xs: $base-side-padding-xs,
  sm: $base-side-padding-sm,
  md: $base-side-padding-md,
  lg: $base-side-padding-lg,
  xl: $base-side-padding-xl
) !default;



// Grid
$grid-columns: 12 !default;

$grid-gutter-xs: $base-gutter-xs !default;
$grid-gutter-sm: $base-gutter-sm !default;
$grid-gutter-md: $base-gutter-md !default;
$grid-gutter-lg: $base-gutter-lg !default;
$grid-gutter-xl: $base-gutter-xl !default;

$grid-gutters: (
  xs: $grid-gutter-xs,
  sm: $grid-gutter-sm,
  md: $grid-gutter-md,
  lg: $grid-gutter-lg,
  xl: $grid-gutter-xl
) !default;

$grid-gutters-narrow: (
  xs: $grid-gutter-xs * .5,
  sm: $grid-gutter-sm * .5,
  md: $grid-gutter-md * .5,
  lg: $grid-gutter-lg * .5,
  xl: $grid-gutter-xl * .5
) !default;

$grid-gutters-wide: (
  xs: $grid-gutter-xs * 2,
  sm: $grid-gutter-sm * 2,
  md: $grid-gutter-md * 2,
  lg: $grid-gutter-lg * 2,
  xl: $grid-gutter-xl * 2
) !default;



// Spacer
$base-spacer: 1rem !default;



// Section block
$section-block-gutter-xs: 3.875em !default;
$section-block-gutter-sm: 3.875em !default;
$section-block-gutter-md: 3.875em !default;
$section-block-gutter-lg: 3.875em !default;
$section-block-gutter-xl: 3.875em !default;

$section-block-gutters: (
  xs: $section-block-gutter-xs,
  sm: $section-block-gutter-sm,
  md: $section-block-gutter-md,
  lg: $section-block-gutter-lg,
  xl: $section-block-gutter-xl
) !default;



// header height
$header-height-xs: 84px !default;
$header-height-sm: 84px !default;
$header-height-md: 120px !default;
$header-height-lg: 132px !default;
$header-height-xl: 218px !default;

$header-height: (
  xs: $header-height-xs,
  sm: $header-height-sm,
  md: $header-height-md,
  lg: $header-height-lg,
  xl: $header-height-xl
) !default;



// z-index
$z-index-header:  4 !default;
$z-index-nav:     2 !default;
$z-index-main:    0 !default;
$z-index-footer:  1 !default;
$z-index-pagetop: 5 !default;
