@charset 'UTF-8';

// breadlist-wrapper
//
.breadlist-wrapper {
  background: $white;
  border-top: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
  margin-bottom: 2em;
  padding: 1em 0;
  @include media(md) {
    padding: 1em 0;
  }
  @include media(lg) {
    padding: 1em 0;
  }
}



// breadlist-container
//
.breadlist-container {
  @include make-container;
}



// breadlist
//
ol.breadlist {
  display: flex;
  align-items: center;
  font-size: .725em;

  li {
    color: inherit;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + li::before {
      content: '＞';
      color: $gray-500;
      padding-right: .5em;
      padding-left: .5em;
    }

    a {
      color: $second;
      &::active,
      &::visited {
        color: inherit;
      }
    }

    &.current {
      color: $gray-500;
    }
  }
}
