@charset 'UTF-8';

// font size
$btn-font-size-small:  0.875rem !default;
$btn-font-size-normal: 1.125rem !default;
$btn-font-size-large:  1.25rem !default;

$btn-font-sizes: (
  'small':  $btn-font-size-small,
  'normal': $btn-font-size-normal,
  'large':  $btn-font-size-large
) !default;



// padding 左右
$btn-x-padding-small:  .5rem !default;
$btn-x-padding-normal: 1rem !default;
$btn-x-padding-large:  1.125rem !default;

$btn-x-paddings: (
  'small':  $btn-x-padding-small,
  'normal': $btn-x-padding-normal,
  'large':  $btn-x-padding-large
) !default;



// padding 上下
$btn-y-padding-small:  .5rem !default;
$btn-y-padding-normal: 1rem !default;
$btn-y-padding-large:  1.125rem !default;

$btn-y-paddings: (
  'small':  $btn-y-padding-small,
  'normal': $btn-y-padding-normal,
  'large':  $btn-y-padding-large
) !default;


// border 太さ
$btn-border-width: 2px !default;

// background color
$btn-background-colors: (
  'base':   $base,
  'first':  $first,
  'accent': $accent,
  'second': $second,
  'third':  $third,
  'fourth': $fourth,
  'five':   $five
) !default;

$btn-hover-background-colors: (
  'base':  $white,
  'first':  $white,
  'accent': $white,
  'second': $white,
  'third':  $white,
  'fourth': $white,
  'five':   $white
) !default;

$btn-square-hover-background-colors: (
  'base':   lighten($base,  60%),
  'first':  lighten($first,  60%),
  'accent': lighten($accent, 35%),
  'second': #fff,
  'third':  lighten($third, 30%),
  'fourth': lighten($fourth, 45%),
  'five':   lighten($five, 50%)
) !default;

// text color
$btn-colors: (
  'base':   $white,
  'first':  $white,
  'accent': $white,
  'second': $white,
  'third':  $white,
  'fourth': $white,
  'five':   $white
) !default;

$btn-hover-colors: (
  'base':   $base,
  'first':  $first,
  'accent': $accent,
  'second': $second,
  'third':  $third,
  'fourth': $fourth,
  'five':   $five
) !default;

