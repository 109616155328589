@charset 'UTF-8';

.box-nav-wrapper {
  position: relative;
}

.box-nav-container {
  display: flex;
  flex-flow: row wrap;
}
