@charset 'UTF-8';

// 外来担当医表
//

// 男性医師名のカラー
.doctor-name-male {
  color: $gray-dark;
}
// 女性医師名のカラー
.doctor-name-female {
  color: #c7426e;
}



.section-schedule-wrapper {
  margin-bottom: 4rem;
}



// 外来担当医表テーブル
table.section-schedule-table {
  font-size: 13px;
  width: 950px;
  @include media(lg) {
    font-size: 15px;
    width: 100%;
  }
  .update-at-schedule {
    font-weight: 700;
    text-align: left;
    margin-bottom: 1em;
  }

  th, td {
    border: 1px solid $table-border-color;
    padding: .5rem;
    text-align: center;
  }
  th {
    background: $base;
    color: $white;
    width: calc(100% / 7);
  }
  td {
    background-color: $white;

    &[class^='time-zone'] {
      background: $white;
      vertical-align: middle;
      [class^='zone'] {
        color: $white;
        display: inline-block;
        font-size: .8125rem;
        font-weight: 700;
        margin: 0;
        padding: .125rem .5rem calc(.125rem - 1px);
      }
      // 午前
      .zone_0 {
        background-color: #ec83ab;
      }
      // 午後
      .zone_1 {
        background-color: #60a8ef;
      }
      .time-box-exp {
        font-weight: 700;
        line-height: 1;
        margin-top: .5rem;
        p {
          font-size: .875rem;
          margin-bottom: .25rem;
        }
      }
    }
    // 午前
    &.time-zone_0 {
      background-color: #fcf5f8;
    }
    // 午後
    &.time-zone_1 {
      background-color: #f3f5f9;
    }
  }

}



$doctor-container-gutter-xs: 8px;

.doctor-container {
  padding: $doctor-container-gutter-xs;
  position: relative;
  & + & {
    &:before {
      content: '';
      border-top: 1px solid $gray-400;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 1px;
    }
  }
  // 予約について
  .yoyaku {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    padding: 4px 8px;
    &.type_1 {
      background: $base;
      color: $white;
    }
    &.type_2 {
      background: $first;
      color: $white;
    }
  }

  .doctor-name {
    font-size: 1.125rem;
    font-weight: 700;
  }

  .comment, .comment-sub {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 4px;
  }
}



.schedule-lower-comment {
  margin-top: 1em;
}

.lower-comment {
  font-size: .857142em;
  font-weight: 500;
  line-height: 1.4;
  & + & {
    margin-top: .5em;
  }
}



// スクロールを出すための wrapper
.table-scroll-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}



// 診療科一覧
.section-schedule-container {
  & + & {
    margin-top: 4em;
  }
}

.section-schedule-heading {
  font-size: 2rem;
  font-weight: 700;
  margin: 7rem 0 1.5rem;
  padding: 1rem .5rem;
  position: relative;
  @include media(sm){
    font-size: calc(2rem + ((1vw - .64rem) * .7143));
  }
  @include media(xl){
    font-size: 2.4rem;
  }
  &::before {
    content: '';
    background-image: linear-gradient(to left, $second 0%, $base 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }
}



// 外来担当医表のPDFリンクを出力する
.schedule-pdf-wrapper {
  margin: 1em 0;
}
