@charset 'UTF-8';

$kanren-link-color: $base;
$break-point: 768px;

.kanren-link-wrapper {
  .kanren-link-container {
    .kanren-link-block{
      display: flex;
      flex-wrap: wrap;
      margin: -1rem;
      .kanren-link-column {
        width: 100%;
        padding: 1rem;
        @media screen and ( min-width: $break-point ){
          width: 33.33333%;
        }
      }
    }
    & + .kanren-link-container {
      margin-top: 4rem;
    }
  }
}

.link-list-group {
  .link-list-item {
    display: block;
    position: relative;
    padding: 0.5rem 2rem 0.5rem 0.75rem;
    transition: background $base-transition;
    color: #333;
    &::before{
      content: '';
      background-image: set-svg($arrow-right, $base);
      background-repeat: no-repeat;
      background-size: contain;
      width: 0.75rem;
      height: 0.75rem;
      display: inline-block;
      position: absolute;
      right: 0.5rem;
      top: 55%;
      transform: translateY(-55%);
    }
    &:hover{
      background: lighten($kanren-link-color, 65%);
    }
  }
}

.pref-heading {
  font-size: $h2-font-size;
  margin-bottom: $h2-margin-bottom;
  padding: 1.125rem 0;
  border-top: solid 1px #999999;
  border-bottom: solid 1px #999999;
  font-family: $notosans;
  font-weight: 700;
  letter-spacing: 0.0625em;
}

.facility-heading {
  font-size: $h3-font-size;
  margin-bottom: $h3-margin-bottom;
  padding: 0.75rem 1rem;
  background-color: #D9E1EE;
  font-family: $notosans;
  font-weight: 700;
  letter-spacing: 0.0625em;
}
