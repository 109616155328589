@charset 'UTF-8';



// Base font family
$base-font-family: 'YakuHanJPs', -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans JP', 'メイリオ', Meiryo, Arial, sans-serif;
//$base-font-family: 'YakuHanJPs', -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, 'メイリオ', Meiryo, Arial, sans-serif;

$notosans: 'Noto Sans JP', sans-serif;

// Base text color
$base-text-color: $gray-dark !default;

// Base background color
$base-background-color: $white !default;

// Base font size
$base-font-size: 1rem !default;

$font-size-xs: 14px !default;
$font-size-sm: 14px !default;
$font-size-md: 15px !default;
$font-size-lg: 16px !default;
$font-size-xl: 16px !default;

$font-size: (
  xs: $font-size-xs,
  sm: $font-size-sm,
  md: $font-size-md,
  lg: $font-size-lg,
  xl: $font-size-xl
) !default;

$font-size-percentage: (
  'xsmall'  : 62.5%,
  'small'   : 75%,
  'medium'  : 87.5%,
  'large'   : 112.5%,
  'xlarge'  : 125%,
  'xxlarge' : 150%,
  'exlarge' : 200%
) !default;

$base-line-height: 1.6 !default;

// Heading
$h1-font-size: $base-font-size * 1.875 !default;
$h2-font-size: $base-font-size * 1.375 !default;
$h3-font-size: $base-font-size * 1.125 !default;
$h4-font-size: $base-font-size * 1.0625 !default;
$h5-font-size: $base-font-size !default;
$h6-font-size: $base-font-size !default;

$h1-margin-bottom: 3.375rem !default;
$h2-margin-bottom: 2.25rem !default;
$h3-margin-bottom: 1.5rem !default;
$h4-margin-bottom: .75rem !default;
$h5-margin-bottom: .75rem !default;
$h6-margin-bottom: .75 !default;

$heading-line-height:   1.4 !default;

// Paragraph p.lead
$lead-letter-spacing-xs: 0 !default;
$lead-letter-spacing-sm: 0 !default;
$lead-letter-spacing-md: 0 !default;
$lead-letter-spacing-lg: 0 !default;
$lead-letter-spacing-xl: 0 !default;

$lead-letter-spacing: (
  xs: $lead-letter-spacing-xs,
  sm: $lead-letter-spacing-sm,
  md: $lead-letter-spacing-md,
  lg: $lead-letter-spacing-lg,
  xl: $lead-letter-spacing-xl
) !default;

$lead-line-height-xs: 1.6 !default;
$lead-line-height-sm: 1.6 !default;
$lead-line-height-md: 1.6 !default;
$lead-line-height-lg: 1.6 !default;
$lead-line-height-xl: 1.6 !default;

$lead-line-height: (
  xs: $lead-line-height-xs,
  sm: $lead-line-height-sm,
  md: $lead-line-height-md,
  lg: $lead-line-height-lg,
  xl: $lead-line-height-xl
) !default;

$lead-top-spacer-xs: 1em !default;
$lead-top-spacer-sm: 1em !default;
$lead-top-spacer-md: 1em !default;
$lead-top-spacer-lg: 1em !default;
$lead-top-spacer-xl: 1em !default;

$lead-top-spacer: (
  xs: $lead-top-spacer-xs,
  sm: $lead-top-spacer-sm,
  md: $lead-top-spacer-md,
  lg: $lead-top-spacer-lg,
  xl: $lead-top-spacer-xl
) !default;

// breakpoint ごとの比率
$scale-sm: math.div($font-size-sm, $font-size-xs);
$scale-md: math.div($font-size-md, $font-size-xs);
$scale-lg: math.div($font-size-lg, $font-size-xs);
$scale-xl: math.div($font-size-xl, $font-size-xs);

$scale: (
  sm: $scale-sm,
  md: $scale-md,
  lg: $scale-lg,
  xl: $scale-xl
) !default;

// Base box shadow
$base-box-shadow: 0 .5rem 1rem rgba($black, .15) !default;
$base-box-shadow-small: 0 0 .428571rem 0 rgba($black, .2) !default;

// Base transition
$base-transition: .2s ease-out;

// メニュー open 時に付与するクラス名
$menu-open-class:  '.visible-mobile-menu';
$menu-close-class: '.invisible-mobile-menu';

// Links
$base-link-color:            $third !default;
$base-link-decoration:       none !default;
$base-link-hover-color:      darken( $third, 10% ) !default;
$base-link-hover-decoration: none !default;

// Horizontal line
$hr-border: 1px solid $gray-400 !default;

//border
$base-border-color: #999999;

// Table
$table-border-color:        $base-border-color !default;
$table-th-background-color: $base !default;
$table-td-background-color: $white    !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29'),
) !default;
