@charset 'UTF-8';

$burger-icon-size: 38px;
$burger-padding: 10px;

// burger-wrapper
.burger-wrapper {
  // display: inline-flex;
  // align-items: center;
  // justify-content: flex-start;
  @include media(lg){
    display: none;
  }
  &::after{
    content: '';
    background: rgba( $white, 1 );
    display: inline-block;
    position: fixed;
    width: calc( $burger-icon-size + $burger-padding * 2 );
    height: calc( $burger-icon-size + $burger-padding * 2 );
    top: 17px;//計算されないと怖いので手打ちにした（calcは↓）
    //top: calc( ( $header-height-xs + $header-border-weight - ( $burger-icon-size + $burger-padding * 2 )) * 0.5 );
    z-index: 3;
    left: map-get($base-side-padding, xs);
    border-radius: 50%;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.2);
    @include media(md){
      top: 35px;//計算されないと怖いので手打ちにした（calcは↓）
      //top: calc( ( $header-height-md + $header-border-weight - ( $burger-icon-size + $burger-padding * 2 )) * 0.5 );
    }
  }
}

#{$menu-open-class}{
  .burger-wrapper {
    &::after{
      z-index: 6;
    }
  }
}

// burger
//
.burger-icon {

  cursor: pointer;
  display: inline-block;
  position: fixed;
  top: 17px;//計算されないと怖いので手打ちにした（calcは↓）
  //top: calc( ( $header-height-md + $header-border-weight - ( $burger-icon-size + $burger-padding * 2 )) * 0.5 );
  z-index: 7;
  left: map-get($base-side-padding, xs);
  visibility: visible;
  margin: $burger-padding;
  @include media(md){
    top: 35px;//計算されないと怖いので手打ちにした（calcは↓）
    //top: calc( ( $header-height-md + $header-border-weight - ( $burger-icon-size + $burger-padding * 2 )) * 0.5 );
  }

  // background-color: $white;
  // background-image: set-svg($burger, $base);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: contain;
  display: inline-block;
  width: $burger-icon-size;
  height: $burger-icon-size;


  .burger-svg{
    transition: transform $base-transition;
    rect{
      fill: $base;
      transition: 
        fill $base-transition, 
        width $base-transition, 
        height $base-transition, 
        opacity $base-transition;
    }
  }
  
  // OPEN
  #{$menu-open-class} & {
    .burger-svg{
      transform: rotate(45deg);
      rect{
        &:nth-child(2){
          fill: $accent;
          height: 20px;
        }
        &:nth-child(4){
          fill: $accent;
          width: 20px;
        }
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(8){
          opacity: 0;
        }
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(7),
        &:nth-child(9){
          //opacity: 0.5;
        }
      }
    }
  }
}