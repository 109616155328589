@charset 'UTF-8';

// footer-wrapper
//
.footer-wrapper {
  background: $first;
  position: relative;
}

// footer-container
//
.footer-container {
  @include make-container;
}

// footer-inner
//
.footer-inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 2.857142rem 0 2.428571rem;
}

// footer-inner に内包して使用する
//
// footer-inner-left
.footer-inner-left {
  flex: 0 0 100%;
  @include media(lg){
    flex: 0 0 auto;
  }
}

// footer-inner-center
.footer-inner-center {
  flex: 0 0 auto;
}


// footer-inner-right
.footer-inner-right {
  flex: 0 0 100%;
  margin: 2.428571rem 0 0;
  overflow: hidden;
  @include media(lg){
    flex: 1;
    margin: 0 0 0 2.428571rem;
  }
}

// footer brand
.footer-brand {
  margin: 0 auto;
  width: 200px;
  @include media(lg){
    width: 376px;
  }
}



// プライバシーポリシー 関連リンク お問い合わせ
ul.footer-utilities-list {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  @include media(lg){
    justify-content: flex-end;
  }

  li {
    &:nth-of-type(n+2){
      margin-left: 1rem;
    }
    a {
      color: $white;
      display: flex;
      align-items: center;
      font-size: .928571428rem;
      position: relative;
      span {
        display: inline-block;
        &::after {
          content: '';
          background-image: set-svg($arrow-right, $white);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: inline-block;
          margin-left: .4rem;
          width: .7rem;
          height: .7rem;
        }
      }
    }
  }
}



// バナー スライダー
.footer-slider-wrapper {
  margin: 2.428571rem 0 0;
  position: relative;
  @include media(lg){
    margin: 1.25rem 0 0 auto;
    width: 432px;
  }
}

.footer-slider-container {
  width: 100%;
  height: 100%;
}
