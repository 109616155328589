@charset 'UTF-8';

// keyframs

// display: none; からのフェードイン
//
@keyframes show {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes transY {
  0% {
      margin-top: -16px;
  }
  100% {
      margin-top: 0;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba( 0, 0, 0, 0.0 );
  }
  50% {
    box-shadow: 0px 0px 0px $form-radio-ripple-size rgba( 0, 0, 0, 0.1 );
  }
  100% {
    box-shadow: 0px 0px 0px $form-radio-ripple-size rgba( 0, 0, 0, 0 );
  }
}

@keyframes rippleRev {
  0% {
    box-shadow: 0px 0px 0px $form-radio-ripple-size rgba( 0, 0, 0, 0 );
  }
  50% {
    box-shadow: 0px 0px 0px $form-radio-ripple-size rgba( 0, 0, 0, 0.1 );
  }
  100% {
    box-shadow: 0px 0px 0px 1px rgba( 0, 0, 0, 0.0 );
  }
}

@keyframes left-open{
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes  left-close{
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}

@keyframes right-open{
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes  right-close{
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw);
  }
}

@keyframes top-open{
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes  top-close{
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}

@keyframes bottom-open{
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes  bottom-close{
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}

@keyframes bgChange {
  0%{
    background-size: contain, 0 0;
  }
  100%{
    background-size: 0 0, contain;
  }
}