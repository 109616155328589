@charset 'UTF-8';

// variables
$top-section-gutter-xs: 32px !default;
$top-section-gutter-sm: 32px !default;
$top-section-gutter-md: 32px !default;
$top-section-gutter-lg: 72px !default;
$top-section-gutter-xl: 72px !default;

$top-section-gutters: (
  xs: $top-section-gutter-xs,
  sm: $top-section-gutter-sm,
  md: $top-section-gutter-md,
  lg: $top-section-gutter-lg,
  xl: $top-section-gutter-xl
) !default;


// Wrapper
.top-section-wrapper {
  position: relative;
  @each $key, $value in $breakpoints {
    @include media($key, true){
      padding-top: map-get($top-section-gutters, $key);
      padding-bottom: map-get($top-section-gutters, $key);
    }
  }
  &:first-child {
    padding-top: 0;
  }
}