@charset 'UTF-8';

// variable
//
$pagetop-active-class-name: '.pagetop-active';



.pagetop {
  background: rgba( $base, .9 );
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: .4rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  text-align: center;
  text-decoration: none;
  visibility: hidden;
  transition: all $base-transition;
  width: 44px;
  height: 44px;
  z-index: $z-index-pagetop;
  // 矢印


  
  &:after {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: set-svg($arrow-down, $white);
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    transition: transform .3s ease-out;
    @include media(md) {
      width: 28px;
      height: 28px;
    }
    @include media(lg) {
      width: 32px;
      height: 32px;
    }
  }
  &:hover {
    background: rgba( lighten( $base, 10% ), .9);
  }

  &#{$pagetop-active-class-name} {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  @include media(md) {
    width: 50px;
    height: 50px;
  }

  @include media(lg) {
    bottom: 11.5rem;
    right: 1rem;
    width: 60px;
    height: 60px;
  }
}
