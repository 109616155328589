@charset 'UTF-8';

.label {
  background: $base;
  border: $label-border-width solid transparent;
  border-radius: $label-border-radius;
  color: $white;
  display: inline-block;
  font-size: $label-font-size;
  line-height: 1 !important;
  padding: $label-padding-y-xs $label-padding-x-xs;
  text-align: center;
  position: relative;

  @include media(sm) {
    padding: $label-padding-y-sm $label-padding-x-sm;
  }
  @include media(md) {
    padding: $label-padding-y-md $label-padding-x-md;
  }
  @include media(lg) {
    padding: $label-padding-y-lg $label-padding-x-lg;
  }
  @include media(xl) {
    padding: $label-padding-y-xl $label-padding-x-xl;
  }
  // bacground color pattern
  @each $name, $value in $label-background-colors {
    &.#{$name} {
      background: $value;
      color: map-get( $label-colors, $name );
    }
  }
  // Color Pattern gray & Basic color
  @each $name, $value in $commons-colors {
    &.#{$name} {
      background: $value;
      @if ( $name == gray-900 or $name == gray-800 or $name == gray-700 or $name == gray-600 or $name == gray-500 or $name == 'black' or $name == 'red' or $name == 'blue' or $name == 'pink' ){
        color: $white;
      } @else {
        color: inherit;
      }
    }
  }

  &.outline {
    background: $white;
    border: $label-border-width solid $base;
    color: $base;

    // border color pattern
    @each $name, $value in $commons-colors {
      &.#{$name} {
        border: $label-border-width solid $value;
        color: $value;
      }
    }
  }

  &.expanded {
    display: block;
  }
}

span.label {
  margin-left: .5em;
  margin-right: 0;
  &.left {
    margin-left: 0;
    margin-right: .5em;
  }
}
