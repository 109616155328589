@charset 'UTF-8';

@mixin btn-common {
  background: $third;
  border: $btn-border-width solid $third;
  color: $white;
  cursor: pointer;
  line-height: 1.42875;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: background $base-transition, color $base-transition;
  vertical-align: middle;
  position: relative;
  font-family: $notosans;
  font-weight: 500;
  letter-spacing: 0.0625em;
  display: inline-flex;
  // hover
  &:hover {
    background: $white;
    color: $third;
  }
  // icon-type
  @each $name, $icon in $svg-icon-types {
    &.#{$name},
    &.docs {
      padding-right: 2.25em;
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        color: $white;
        background-image: set-svg($icon, $white);
        top: 50%;
        transform: translateY(-50%);
        right: 0.5em;
      }
      &:hover {
        &::after{
          background-image: set-svg($icon, $third);
        }
      }
    }
  }
  &.docs{
    &::after {
      background-image: url('../../images/common/docs.svg');
    }
    &:hover{
      &::after {
        background-image: url('../../images/common/docs.svg');
      }
    }
  }
  // default
  &.default{
    &::after{
      content: '';
      background-image: set-svg($arrow, $white);
      display: inline-block;
      position: absolute;
      color: $white;
    }
    &:hover {
      &::after{
        background-image: set-svg($arrow, $third);
      }
    }
    // Colors
    @each $color-name, $value in $btn-background-colors {
      &.#{$color-name} {
        &::after{
          background-image: set-svg($arrow, #{map-get( $btn-colors, $color-name )});
        }
        &:hover {
          &::after{
            background-image: set-svg($arrow, #{map-get( $btn-hover-colors, $color-name )});
          }
        }
      }
    }
    // Outline
    &.outline {
      &::after{
        background-image: set-svg($arrow, $third);
      }
      // Outline hover
      &:hover {
        &::after{
          background-image: set-svg($arrow, $white);
        }
      }
      // Outline colors
      @each $color-name, $value in $btn-background-colors {
        &.#{$color-name} {
          &::after{
            background-image: set-svg($arrow, #{map-get( $btn-hover-colors, $color-name )});
          }
          &:hover {
            &::after{
              background-image: set-svg($arrow, #{map-get( $btn-colors, $color-name )});
            }
          }
        }
      }
    }
  }
  // Colors
  @each $color-name, $value in $btn-background-colors {
    &.#{$color-name} {
      background: $value;
      color: map-get( $btn-colors, $color-name );
      border: $btn-border-width solid $value;
      &:hover {
        background: map-get( $btn-hover-background-colors, $color-name );
        color: map-get( $btn-hover-colors, $color-name );
      }
      // icon-type
      @each $icon-name, $icon in $svg-icon-types {
        &.#{$icon-name} {
          &::after {
            background-image: set-svg($icon, #{map-get( $btn-colors, $color-name )});
          }
          &:hover {
            &::after{
              background-image: set-svg($icon, #{map-get( $btn-hover-colors, $color-name )});
            }
          }
        }
      }
    }
  }
  // Sizes
  @each $name, $value in $btn-font-sizes {
    &.#{$name} {
      font-size: $value;
      padding-top: map-get( $btn-y-paddings, $name );
      padding-bottom: map-get( $btn-y-paddings, $name );
      //padding: map-get( $btn-y-paddings, $name ) map-get( $btn-x-paddings, $name );
    }
  }
  // Outline
  &.outline {
    background: $white;
    border: $btn-border-width solid $third;
    color: $third;
    // Outline hover
    &:hover {
      background: $third;
      color: $white;
    }
    // icon-type
    @each $name, $icon in $svg-icon-types {
      &.#{$name} {
        &::after {
          background-image: set-svg($icon, $third);
        }
        &:hover {
          &::after{
            background-image: set-svg($icon, $white);
          }
        }
      }
    }
    // Outline colors
    @each $color-name, $value in $btn-background-colors {
      &.#{$color-name} {
        border: $btn-border-width solid $value;
        color: map-get( $colors, $color-name );
        &:hover {
          background: $value;
          color: $white;
        }
        // icon-type
        @each $icon-name, $icon in $svg-icon-types {
          &.#{$icon-name} {
            &::after {
              background-image: set-svg($icon, #{map-get( $btn-hover-colors, $color-name )});
            }
            &:hover {
              &::after{
                background-image: set-svg($icon, #{map-get( $btn-colors, $color-name )});
              }
            }
          }
        }
      }
    }
  }
  // Expanded
  &.expanded {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
}

// Button base
//
.btn {
  @include btn-common;
  align-items: center;
  justify-content: center;
  font-size: $btn-font-size-normal;
  height: 100%;
  padding: $btn-y-padding-normal $btn-x-padding-normal;
  // icon-type
  @each $name, $icon in $svg-icon-types {
    &.#{$name},
    &.docs {
      padding-left: 2.25em;
      &::after {
        width: 1.2em;
        height: 1.2em;
      }
    }
  }
  // default
  &.default{
    padding-right: 2em;
    padding-left: 2em;
    &::after{
      width: 1.4em;
      height: 1.4em;
      right: 0.375em;
      bottom: 0;
    }
  }
}

// Button left
//
.btn-left {
  @include btn-common;
  align-items: center;
  font-size: $btn-font-size-normal;
  height: 100%;
  padding: $btn-y-padding-normal $btn-x-padding-normal $btn-y-padding-normal 1.25em;
  text-align: left;
  // icon-type
  @each $name, $icon in $svg-icon-types {
    &.#{$name},
    &.docs {
      &::after {
        width: 1.2em;
        height: 1.2em;
      }
    }
  }
  // default
  &.default{
    padding-right: 2em;
    &::after{
      width: 1.4em;
      height: 1.4em;
      right: 0.375em;
      bottom: 0;
    }
  }
}

// Button narrow
//
.btn-narrow {
  @include btn-common;
  height: 3.625em;
  font-size: 1rem;
  align-items: center;
  padding: 0 0.875em 0 0.875em;
  text-align: left;
  // icon-type
  @each $name, $icon in $svg-icon-types {
    &.#{$name},
    &.docs {
      &::after {
        content: '';
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
  // default
  &.default{
    padding-right: 2.25em;
    &::after{
      width: 1.5em;
      height: 1.5em;
      right: 0.5em;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// Button square
//
.btn-square{
  background: $base;
  padding-top: calc( 100% - 4px );
  position: relative;
  display: block;
  border: solid 2px $base;
  cursor: pointer;
  &:hover{
    @include media(md){
      .btn-text{
        transition: color .4s;
        color: $base;
        &::after{
          //background-image: set-svg($arrow, $base);
          animation-name: bgChange;
          animation-duration:0.4s;
          animation-fill-mode:forwards;
          background-size: 0 0, contain;
        }
      }
      &::before{
        width: 100%;
        height: 100%;
        transition: width 0.4s cubic-bezier(0.8, 0, 0.2, 1) 0s, height 0.4s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      }
    }
  }
  &::before{
    content: '';
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    background: lighten( $base, 60% );
    position: absolute;
    display: block;
    z-index: 0;
    transition: width 0.2s cubic-bezier(0.8, 0, 0.2, 1) 0s, height 0.2s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  }
  .btn-text{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.25em 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 1.375rem;
    font-family: $notosans;
    font-weight: 700;
    //letter-spacing: 0.1875em;
    letter-spacing: 0.0625em;
    text-align: center;
    line-height: 1.4;
    z-index: 1;
    transition: color .2s;
    @include media(lg){
      font-size: 1.5rem;
    }
    &::after{
      content: '';
      background-image: set-svg($arrow, $white),set-svg($arrow, $base);
      background-size: contain, 0 0;
      background-repeat: no-repeat;
      width: 1.75em;
      height: 1.75em;
      display: inline-block;
      position: absolute;
      right: 0.375em;
      bottom: 0;
      color: $white;
      transition: background 0.4s;
    }
  }

  // Colors
  @each $name, $value in $btn-background-colors {
    &.#{$name} {
      background: $value;
      border: solid 2px $value;
      &:hover{
        @include media(md){
          .btn-text{
            color: map-get( $btn-hover-colors, $name );
            &::after{
              //background-image: set-svg($arrow, #{map-get( $btn-hover-colors, $name )});
            }
          }
        }
      }
      &::before{
        background: map-get($btn-square-hover-background-colors, $name);
      }
      .btn-text{
        color: map-get( $btn-colors, $name );
        @include media(md){
          font-size: 1.5rem;
        }
        &::after{
          background-image: set-svg($arrow, #{map-get( $btn-colors, $name )}), set-svg($arrow, #{map-get( $btn-hover-colors, $name )});
        }
      }
    }
  }

}
