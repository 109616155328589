@charset 'UTF-8';

.top-slider-wrapper {
  width: 100%;
  height: 17rem;
  position: relative;
  @include media(md){
    //aspect-ratio: 2.5/1;
    height: auto;
    padding-top: calc(100% * 768 / 1920 );
  }
}

.top-slider-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.top-background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: inherit;
}

.top-background-headline {
  color: $white;
  font-size: 1.285714rem;
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  text-align: right;
  text-shadow: 4px 4px 12px rgba($black, .78);
  z-index: 2;
  font-family: $notosans;
  font-weight: 700;
  letter-spacing: 0.1875em;
  @include media(md){
    font-size: 2.25rem;
    bottom: 4rem;
    right: 3rem;
  }
  @include media(xl){
    font-size: 3rem;
    bottom: 7rem;
    right: 6rem;
  }
}
