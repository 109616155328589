@charset 'UTF-8';

// wrapper
.local-nav-wrapper {
  background: transparent;
  ul.local-nav {
    @include media(lg){
      display: flex;
      justify-content: space-between;
      position: relative;
    }
  }
  // メインメニュー
  li.nav-parent {
    @include media(lg){
      width: 100%;
    }
    & + .nav-parent {
      border-top: solid 1px $white;
      @include media(lg){
        border-top: none;
        border-left: solid 1px $white;
      }
    }
  }
  .nav-parent-link {
    background-color: $base;
    display: flex;
    flex-flow: column wrap;
    align-items: baseline;
    padding: 1rem;
    position: relative;
    color: $white;
    height: 100%;
    text-align: center;
    &::after {
      content: '';
      background-image: set-svg($arrow, $white);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      @include media(lg){
        content: none;
      }
    }
    @include media(lg){
      align-items: center;
      justify-content: center;
      transition: background-color $base-transition, color $base-transition;
      &::after {
        display: none;
      }
      &:hover{
        background-color: lighten($base, 60%);
        color: $base-text-color;
      }
    }
    // テキスト
    span.headline {
      display: block;
      font-size: .857142rem;
      font-weight: 700;
      font-family: $notosans;
      letter-spacing: 0.0625em;
      @include media(lg){
        font-size: 1rem;
      }
    }
    span.en {
      display: none;
      @include media(lg){
        color: #999999;
        display: block;
        font-size: 1.25em;
        font-family: $notosans;
        letter-spacing: 0.0625em;
      }
    }
  }
  .hover {
    a.nav-parent-link{
      background-color: lighten($base, 60%);
      color: $base-text-color;
    }
  }
  // チャイルドメニュー
  .nav-child-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height $base-transition;
    z-index: 6;
    @include media(lg){
      position: absolute;
      left: 0;
      width: 100%;
      transition: max-height $base-transition 0.2s;
    }
  }
  .nav-child-container {
    background-color: lighten($base, 60%);
    padding: 0.5rem 0;
    @include media(lg){
      display: flex;
      margin: 0 auto;
      padding: 1rem map-get( $base-side-padding, lg );
      max-width: get_max_width( lg );
    }
    @include media(xl){
      margin: 0 auto;
      padding: 1rem map-get( $base-side-padding, xl );
      max-width: get_max_width( xl );
    }
  }
  .nav-child-inner{
    @include media(lg){
      padding: 1rem;
      &.col-12{
        width: calc(100% * (12 / 12));
      }
      &.col-11{
        width: calc(100% * (11 / 12));
      }
      &.col-10{
        width: calc(100% * (10 / 12));
      }
      &.col-9{
        width: calc(100% * (9 / 12));
      }
      &.col-8{
        width: calc(100% * (8 / 12));
      }
      &.col-7{
        width: calc(100% * (7 / 12));
      }
      &.col-6{
        width: calc(100% * (6 / 12));
      }
      &.col-5{
        width: calc(100% * (5 / 12));
      }
      &.col-4{
        width: calc(100% * (4 / 12));
      }
      &.col-3{
        width: calc(100% * (3 / 12));
      }
      &.col-2{
        width: calc(100% * (2 / 12));
      }
      &.col-1{
        width: calc(100% * (1 / 12));
      }
    }
  }
  .nav-child-item{
    //max-height: 0;
    overflow: hidden;
    transition: max-height $base-transition;
    @include media(lg){
      max-height: none;
      overflow: auto;
      transition: none;
      display: flex;
      margin: -1rem;
    }
  }
  .nav-child-list{
    @include media(lg){
      padding: 1rem;
      flex: 1;
    }
    br{
      display: none;
      @include media(lg){
        display: block;
      }
    }
  }
  .nav-child-headline{
    background-color: $base;
    color: $gray-dark;
    display: block;
    font-size: .857142rem;
    padding: 0.875rem 0.875rem 0.875rem 1.5rem;
    position: relative;
    font-weight: 500;
    font-family: $notosans;
    letter-spacing: 0.0625em;
    @include media(lg){
      color: $base;
      border-bottom: solid 1px $base;
      margin-bottom: 1.5rem;
      font-size: 1.25rem;
      font-weight: bold;
      background-color: transparent;
      padding: 0.25rem 0;
    }
    &::after {
      content: '';
      background-image: set-svg($arrow-down, $base);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      @include media(lg){
        content: none;
      }
    }
  }
  .nav-child-link{
    color: $base-text-color;
    display: block;
    font-size: .857142rem;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    position: relative;
    font-weight: 500;
    font-family: $notosans;
    letter-spacing: 0.0625em;
    line-height: 1.4;
    @include media(lg){
      background-color: transparent;
      padding: 0.375rem 0 0.375rem 1.125rem;
      font-size: 1rem;
      &::before{
        content: '';
        background-image: set-svg($arrow-right, $base);
        background-repeat: no-repeat;
        background-size: contain;
        width: 0.75rem;
        height: 0.75rem;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 55%;
        transform: translateY(-55%);
      }
    }
    &::after {
      content: '';
      background-image: set-svg($arrow, $base);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      @include media(lg){
        display: none;
      }
    }
  }
  .toggle-item{
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3rem;
    background: darken($base, 10%);
    z-index: 1;
    &::after {
      content: '';
      background-image: set-svg($arrow-down, $white);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      @include media(lg){
        display: none;
      }
    }
    @include media(md){
      display: none;
    }
  }
}


// wrapper
.local-nav-wrapper {
  &.sanka{
    .nav-parent-link {
      background-color: $sanka-base;
      &::after {
        content: '';
        background-image: set-svg($arrow, $white);
      }
      @include media(lg){
        &:hover{
          background-color: $sanka-second;
          color: $sanka-first;
        }
      }
    }
    .hover {
      a.nav-parent-link{
        background-color: $sanka-second;
        color: $sanka-first;
        font-weight: 700;
      }
    }
    // チャイルドメニュー
    .nav-child-container {
      background: $sanka-second;
    }
    .nav-child-link {
      color: $sanka-first;
      font-weight: 700;
      &::after {
        content: '';
        background-image: set-svg($arrow, $sanka-first);
      }
    }
    .nav-child-headline{
      background-color: $sanka-base;
      color: $gray-dark;
      @include media(lg){
        color: $sanka-base;
        border-bottom: solid 1px $sanka-base;
      }
      &::after {
        content: '';
        background-image: set-svg($arrow-down, $sanka-first);
      }
    }
    .nav-child-link{
      @include media(lg){
        &::before{
          content: '';
          background-image: set-svg($arrow-right, $sanka-first);
        }
      }
    }
  }
  .toggle-item{
    background: darken($sanka-base, 5%);
    &::after {
      background-image: set-svg($arrow-down, $white);
    }
  }
}
