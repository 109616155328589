@charset 'UTF-8';

.dpc{

  h1{
    font-size: $h1-font-size;
    margin-bottom: $h1-margin-bottom;
    position: relative;
    position: relative;
    padding-bottom: 1.25rem;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.0625em;
    &::after{
      content: '';
      width: 4.875rem;
      height: 3px;
      position: absolute;
      display: inline-block;
      left: 0;
      bottom: 0;
      background-color: $accent;
    }
  }

  .out_link{
    margin-bottom: 1rem;
  }

  ol.contents{
    list-style: decimal outside;
    padding-left: 1.25em;
  }

  .div-title,
  .div-title-hist{
    font-size: $h2-font-size;
    margin: $h2-margin-bottom 0;
    padding: 1.125rem 0;
    border-top: solid 1px #999999;
    border-bottom: solid 1px #999999;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.0625em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .csv_btn{
    display: inline-block;
    font-size: 0.875rem;
    background-color: $third;
    color: #fff;
    padding: 0.25rem 0.5rem;
    letter-spacing: 0;
    transition: background $base-transition;
    margin-left: auto;
    &:hover{
      color: #fff;
      background-color: darken($third, 10%);
    }
  }

  [id^="cd_top5_nm"],
  [id^="ope_top5_nm"]{
    font-size: $h3-font-size;
    margin: $h3-margin-bottom 0;
    padding: 0.75rem 1rem;
    background-color: #D9E1EE;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.0625em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .template-table{
    text-align: center;
    margin: 1rem 0;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    @include media(md){
      white-space: unset;
    }
    tbody,thead{
      display: table;
      width: 100%;
    }
    -webkit-overflow-scrolling: touch;
    th, td {
      border: 1px solid $table-border-color;
      vertical-align: middle;
    }
    thead{
    th {
      background: $table-th-background-color;
      color: $white;
    }
    }
    th {
      background: lighten($table-th-background-color, 60%);
      padding: .6em 1em;
    }
    td {
      background: $table-td-background-color;
      overflow-wrap : break-word;
      padding: .6em 1em;
    }
  }

  .comment{
    margin: 1rem 0;
  }

}