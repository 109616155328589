@charset 'UTF-8';

// 採用情報（募集職種 & 募集要項）

// 一覧ページ
.base-recruit-table-wrapper {
  position: relative;
}

.base-recruit-list {
  width: 100%;
  li {
    background: transparent;
    &:nth-of-type(n+2){
      margin-top: 4em;
    }
  }
}

.base-recruit-list-heading {
  background: $white;
  border-top: 1px solid $gray-600;
  border-bottom: 1px solid $gray-600;
  color: $gray-dark;
  font-weight: 700;
  margin-bottom: 1em;
  padding: .6em 0;
  text-align: center;
  @include media(lg){
    margin-bottom: 1em;
  }
}

.base-recruit-button-container {
  display: block;
  margin: -.5rem;
  @include media(md){
    display: flex;
    flex-flow: row wrap;
  }
  .btn-col {
    padding: .5rem;
    @include media(md){
      flex: 0 0 percentage(math.div(1,3));
    }
  }
  & + .base-recruit-button-container {
    margin-top: .5rem;
  }
}

// 新卒カラー
$recruit-shin-color: #3FA9F5;
// 中途カラー
$recruit-chu-color: #7AC943;
// パートカラー
$recruit-part-color: #F2ACC6;

.btn-recruit {
  background-color: transparent;
  border: $btn-border-width solid transparent;
  color: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: $notosans;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .0625em;
  line-height: 1;
  padding: $btn-y-padding-normal $btn-x-padding-normal;
  position: relative;
  text-align: center;
  transition: background-color $base-transition, color $base-transition;
  width: 100%;
  height: 100%;
  &::after {
    content: '';
    background-image: set-svg($arrow, $white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }

  // 募集していません
  &._disabled {
    background: #D9E1EE;
    color: $gray-dark;
    display: block;
    padding: ($btn-y-padding-normal + .5rem) $btn-x-padding-normal;
    pointer-events: none;
    &::after {
      content: none;
    }
    span {
      @include media(md){
        display: block;
      }
      @include media(lg){
        display: inline;
      }
    }
  }
  // ジョブオプ
  &.job-op {
    position: relative;
    &::after {
      content: '';
      background-image: set-svg($new-window, $white);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 2rem;
      height: 2rem;
    }
    &._shin:hover {
      &::after {
        background-image: set-svg($new-window, $recruit-shin-color);
      }
    }
    &._chu:hover {
      &::after {
        background-image: set-svg($new-window, $recruit-chu-color);
      }
    }
    &._part:hover {
      &::after {
        background-image: set-svg($new-window, $recruit-part-color);
      }
    }
  }
  // 新卒
  &._shin {
    background-color: $recruit-shin-color;
    border-color: $recruit-shin-color;
    color: $white;
    &:hover {
      background-color: $white;
      color: $recruit-shin-color;
      &::after {
        background-image: set-svg($arrow, $recruit-shin-color);
      }
    }
  }
  // 中途
  &._chu {
    background-color: $recruit-chu-color;
    border-color: $recruit-chu-color;
    color: $white;
    &:hover {
      background-color: $white;
      color: $recruit-chu-color;
      &::after {
        background-image: set-svg($arrow, $recruit-chu-color);
      }
    }
  }
  // パート
  &._part {
    background-color: $recruit-part-color;
    border-color: $recruit-part-color;
    color: $white;
    &:hover {
      background-color: $white;
      color: $recruit-part-color;
      &::after {
        background-image: set-svg($arrow, $recruit-part-color);
      }
    }
  }
}

// 紹介ページへのリンクボタン
.btn-recruit-intro {
  background-color: $base;
  border: $btn-border-width solid $base;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: $notosans;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .0625em;
  padding: $btn-y-padding-normal $btn-x-padding-normal;
  position: relative;
  transition: background-color $base-transition, color $base-transition;
  width: 100%;
  &::after {
    content: '';
    background-image: set-svg($arrow, $white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    background-color: $white;
    color: $base;
    &::after {
      background-image: set-svg($arrow, $base);
    }
  }
}



// 募集要項
//

.base-recruit-detail-wrapper {
  position: relative;
}

.recruit-detail-heading {
  background: $white;
  border-bottom: 2px solid $base;
  color: $base;
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 2em;
  padding: .25em .25em;
}

ul.base-recruit-detail-list {
  width: 100%;
  li {
    background-color: $white;
    border-top: 1px solid $gray-400;
    display: flex;
    flex-direction: column;
    line-height: 1.8;
    //align-items: center;
    &:last-child {
      border-bottom: 1px solid $gray-400;
    }
    @include media(sm){
      flex-direction: row;
    }
  }
}



.detail-head {
  background-color: $white;
  color: $base;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem 0 0;
  text-align: left;
  width: 100%;
  @include media(sm){
    flex: 0 0 20%;
    text-align: right;
  }
}

.detail-content {
  padding: 0 0 1rem;
  @include media(sm){
    margin-left: 4rem;
    padding: 1rem 0;
  }
}
