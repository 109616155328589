@charset 'UTF-8';

// variables
$tab-current-class-name:      '.tab-current';
$tab-current-view-class-name: '.tab-current-view';



.tab-wrapper {
  width: 100%;
}



ul.tab-label {
  //border-bottom: 1px solid $gray-300;
  display: flex;
  flex-flow: row wrap;

  li {
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    flex: 1 0 auto;
    position: relative;

    &:last-child {
      border-right: 1px solid $gray-300;
    }
  }
}

.tab-label-item {
  background: $base;
  cursor: pointer;
  color: $white;
  display: block;
  padding: 1em 0;
  text-align: center;
  //transition: background $base-transition;
  &:hover {
    //color: $gray-600;
    //background: lighten($base, 5%);
  }
  &#{$tab-current-class-name} {
    background: $white;
    color: $gray-dark;
  }
}



.tab-contents {
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.tab-contents-item {
  display: none;
  padding: 1em;

  &#{$tab-current-view-class-name} {
    display: block;
    animation: show .4s linear 0s;
  }
}

.tab-news{
  .tab-label {
    margin: -0.25rem -0.25rem 1rem -0.25rem;
    @include media(lg){
      margin: -0.5rem -0.5rem 1rem -0.5rem;
    }
    li{
      border: none;
      padding: 0.25rem;
      font-family: $notosans;
      font-weight: 500;
      position: relative;
      @include media(lg){
        padding: 0.5rem;
      }
      &::after{
        content: '';
        width: 0;
        height: 0;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-child(1){
        .tab-label-item{
          border: 2px solid $second;
          color: $second;
          &:hover{
            background: $second;
            color: $white;
          }
          &#{$tab-current-class-name} {
            background: $second;
            color: $white;
          }
        }
        &::after{
          border-top: 0.3125rem solid $second;
        }
      }
      &:nth-child(2){
        .tab-label-item{
          border: 2px solid $third;
          color: $third;
          &:hover{
            background: $third;
            color: $white;
          }
          &#{$tab-current-class-name} {
            background: $third;
            color: $white;
          }
        }
        &::after{
          border-top: 0.3125rem solid $third;
        }
      }
      &:nth-child(3){
        .tab-label-item{
          border: 2px solid $fourth;
          color: $fourth;
          &:hover{
            background: $fourth;
            color: $white;
          }
          &#{$tab-current-class-name} {
            background: $fourth;
            color: $white;
          }
        }
        &::after{
          border-top: 0.3125rem solid $fourth;
        }
      }
      &:last-child {
        border-right: none;
      }
    }
    .tab-label-item {
      background: $white;
      padding: 0.5em 0;
      transition: background $base-transition, color $base-transition;
    }
  }
  .tab-contents {
    border: none;
  }
  .tab-contents-item {
    padding: 0;
  }
}