@charset 'UTF-8';

// construct-layout
.construct-layout-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}

.construct-layout-header {
  background: transparent;
  flex: none;
  width: 100%;
  z-index: $z-index-header;
}

.construct-layout-nav {
  background: transparent;
  flex: none;
  width: 100%;
  z-index: $z-index-nav;
}

.construct-layout-main {
  flex: 1 0 auto;
  width: 100%;
  z-index: $z-index-main;
  display: flex;
  flex-direction: column;
  @include media(md){
    background-color: transparent;
    background-image: url('../../images/common/bg.svg');
    background-repeat: repeat-y;
    background-size: 100% auto;
  }
}

// container
.container {
  @include make-container;
}

// 大きいサイズのコンテナ
.container-large {
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  @include media(xl){
    max-width: 1602px;
  }
}



// section-block
.section-block {
  & + & {
    @each $key, $value in $breakpoints {
      @include media($key, true){
        margin-top: map-get($section-block-gutters, $key);
      }
    }
  }
}



// spacer
.spacer {
  margin-top: $base-spacer;
}

.spacer-large {
  margin-top: $base-spacer * 2 !important;
}

.spacer-xlarge {
  margin-top: $base-spacer * 3 !important;
}

.spacer-exlarge {
  margin-top: $base-spacer * 4 !important;
}

.spacer-small {
  margin-top: math.div($base-spacer, 2);
}

.spacer-top-bottom {
  margin: $base-spacer 0;
}

.spacer-top-bottom-large {
  margin: ($base-spacer * 2) 0;
}

.spacer-top-bottom-xlarge {
  margin: ($base-spacer * 3) 0;
}

.spacer-top-bottom-exlarge {
  margin: ($base-spacer * 4) 0;
}

.spacer-top-bottom-small {
  margin: math.div($base-spacer, 2) 0;
}
