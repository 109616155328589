@charset 'UTF-8';



// Media Queries
@mixin media($data, $loop: false )
{
  $key: map-get( $breakpoints, $data );
  @if( $data == 'xs' ){
    @if $loop != false {
      @content;
    } @else {
      $key: map-get( $breakpoints, sm ) - 1px;
      @media screen and ( max-width: $key ) {
        @content;
      }
    }

  } @else {
    @media screen and ( min-width: $key ) {
      @content;
    }
  }
}



// Container
@mixin make-container()
{
  margin: 0 auto;
  @each $key, $value in $breakpoints {
    @include media($key, true){
      padding: 0 map-get($base-side-padding, $key);
      max-width: get_max_width($key);
    }
  }
}



// make grid
@mixin make-grid($width)
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}



// make grid items
@mixin make-grid-items($key, $num)
{
  .#{$key}-#{$num} {
    @include make-grid( math.div(100%, math.div($grid-columns, $num)) );
    margin: 0;
  }
  .#{$key}-#{$num}-center {
    @include make-grid( math.div(100%, math.div($grid-columns, $num)) );
    margin: 0 auto;
  }
  .#{$key}-offset-#{$num} {
    @include make-grid( math.div(100%, math.div($grid-columns, $num)) );
    margin-left: ( math.div(100%, math.div($grid-columns, $num)) );
  }
  .#{$key}-col-#{$num} {
    @include make-grid( math.div(100%, $num) );
    $pad: math.div(map-get($grid-gutters, $key), 2);
    margin: 0;
    padding: #{$pad};
  }
}
