@charset 'UTF-8';

.table {
  text-align: center;
  th, td {
    border: 1px solid $table-border-color;
    vertical-align: middle;
  }
  thead{
    th {
      background: $table-th-background-color;
      color: $white;
    }
  }
  th {
    background: lighten($table-th-background-color, 60%);
    padding: .6em 1em;
  }
  td {
    background: $table-td-background-color;
    overflow-wrap : break-word;
    padding: .6em 1em;
  }
}

.sanka-table {
  text-align: center;
  th, td {
    border: 1px solid $table-border-color;
    vertical-align: middle;
  }
  thead{
    th {
      background: $sanka-first;
      color: $white;
      border-top: 1px solid $sanka-first;
      border-bottom: 1px solid $sanka-first;
      border-right: 1px solid $white;
      border-left: 1px solid $white;
      &:first-child{
        border-left: 1px solid $sanka-first;
      }
      &:last-child{
        border-right: 1px solid $sanka-first;
      }
    }
  }
  th {
    background: $sanka-second;
    color: $sanka-first;
    padding: .6em 1em;
  }
  td {
    background: $table-td-background-color;
    overflow-wrap : break-word;
    padding: .6em 1em;
  }
}

.th-block {
  border-bottom: 1px solid $table-border-color;
  @include media(md) {
    border: none;
  }
  th, td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  th {
    border-bottom: none;
    width: 100%;
    @include media(md) {
      border-bottom: 1px solid $table-border-color;
      width: 15%;
    }
  }
  td {
    border-bottom: none;
    @include media(md) {
      border-top: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
    }
  }
}

// スクロールを出すための wrapper
.table-scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  @include media(md) {
    white-space: normal;
    padding-bottom: 0;
  }
}

.table-fixed{
  table-layout: fixed;
  th{
    width: auto;
  }
}

.table-frugal{
  tr:first-child th{
    border-top: 1px solid $base-border-color;
  }
  th, td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  th{
    padding: 1.25rem 0 0 0;
    text-align: left;
    @include media(md) {
      text-align: right;
      white-space: nowrap;
      padding: 2.125rem 2.125rem 2.125rem 0;
      border-bottom: 1px solid $base-border-color;
      border-top: 1px solid $base-border-color;
    }
  }
  td{
    padding: 0 0 1.25rem 0;
    border-bottom: 1px solid $base-border-color;
    margin-top: 0.5rem;
    @include media(md) {
      margin-top: 0;
      border-top: 1px solid $base-border-color;
      padding: 2.125rem 0 2.125rem 2.125rem;
    }
  }
}

.th-right{
  th{
    text-align: right;
  }
}
.th-center{
  th{
    text-align: center;
  }
}
.th-left{
  th{
    text-align: left;
  }
}
.td-right{
  td{
    text-align: right;
  }
}
.td-center{
  td{
    text-align: center;
  }
}
.td-left{
  td{
    text-align: left;
  }
}

.th-nowrap{
  th{
    white-space: nowrap;
  }
}

.blank-table {
  text-align: center;
  th, td {
    border: 1px solid $table-border-color;
    vertical-align: middle;
  }
  thead{
    th {
      //color: $white;
    }
  }
  th {
    padding: .6em 1em;
  }
  td {
    overflow-wrap : break-word;
    padding: .6em 1em;
  }
  @for $i from 1 through 10 {
    @each $color-name, $value in $colors{
      .bg-#{$color-name}-#{(10 - $i) * 10}  {
        background: lighten($value, $i * 10%);
      }
    }
  }
  .clear{
    background: none;
    border: none;
  }
}