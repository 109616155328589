@charset 'UTF-8';

.half-content-wrapper{
  display: flex;
  margin: -0.5rem;
  justify-content: space-between;
  flex-direction: column;
  @include media(sm){
    margin: -0.75rem;
  }
  @include media(md){
    flex-direction: row;
  }
  @include media(lg){
    margin: -1rem;
  }
  @include media(xl){
    margin: -2rem;
  }
  .half-content-item{
    padding: 0.5rem;
    width: 100%;
    @include media(sm){
      padding: 0.75rem;
    }
    @include media(lg){
      padding: 1rem;
    }
    @include media(xl){
      padding: 2rem;
    }
  }
  .img-wrapper{
    position: relative;
    padding-bottom: 100%;
    .img-item{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.quarter-content-wrapper{
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  @include media(sm){
    margin: -0.75rem;
  }
  @include media(md){
    margin: -0.5rem;
  }
  @include media(lg){
    margin: -0.75rem;
  }
  .quarter-content-item{
    padding: 0.5rem;
    width: 50%;
    @include media(sm){
      padding: 0.75rem;
    }
    @include media(md){
      padding: 0.5rem;
    }
    @include media(lg){
      padding: 0.75rem;
    }
  }
}
