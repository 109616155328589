@charset 'UTF-8';



.news-important-container {
  background-color: $white;
  border: 3px solid $red;
  padding: 1rem;
  @include media(md){
    padding: 1rem 2rem 2rem;
  }
  .important-headline {
    color: $red;
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: .1875em;
    position: relative;
    text-align: center;
    &::before {
      content: '';
      background-color: $accent;
      display: inline-block;
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 4.571428rem;
      height: 3px;
    }
  }
}



ul.important-news-list {
  width: 100%;
  li {
    &:nth-of-type(n+2){
      margin-top: 1rem;
    }
  }

  .news-container {
    color: $gray-dark;
    display: flex;
    flex-flow: row wrap;
    transition: opacity $base-transition;
  }

  // 日付 & NEW
  .date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 100%;
    line-height: 1.6;
    //margin-bottom: .5rem;
    @include media(lg){
      flex-direction: column;
      flex: 0 0 80px;
      margin-bottom: 0;
      margin-right: 1rem;
    }
    // 日付 & NEW
    span {
      display: inline-block;
      &.date {
        font-size: 1rem;
      }
      &.new-label,
      &.label-patient,
      &.label-ordinary,
      &.label-participants {
        display: none;
      }
    }
  }
}
