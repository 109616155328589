@charset 'UTF-8';

// icon
//
.icon {
  display: inline;
  position: relative;
  &::before, &::after {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: inline-block;
    width: $base-icon-size;
    height: $base-icon-size;
    position: relative;
    top: .1em;
  }
  &::after {
    content: '';
    margin-left: 0.25rem;
  }
  &::before {
    content: none;
  }

  &.left {
    &::before {
      content: '';
      margin-right: 0.25rem;
      margin-left: 0;
    }
    &::after {
      content: none;
    }
  }

  @each $name, $icon in $svg-icon-types {
    &.#{$name} {
      @each $color-name, $color in $all-colors {
        &.#{$color-name} {
          &::before, &::after {
            background-image: set-svg($icon, $color);
          }
        }
      }
    }
  }
}
