@charset 'UTF-8';

// variable
$accordion-open-class-name: '.active';



.accordion-wrapper {
  width: 100%;
}



.accordion {
  position: relative;
}



.accordion-heading {
  background: $base;
  border-bottom: 1px solid darken( $base, 5% );
  color: $white;
  cursor: pointer;
  font-size: 1em;
  outline: none;
  margin: 0;
  padding: 1em 3em 1em 1.5em;
  position: relative;
  transition: background $base-transition;
  width: 100%;
  &:hover {
    background: darken( $base, 5% );
  }
  // arrow
  &:after {
    border: 2px solid $white;
    border-right: 0;
    border-top: 0;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: 44%;
    right: 1em;
    transform: rotate(-45deg) translateY(-44%);
    width: 8px;
    height: 8px;
    @include media(lg){
      width: 10px;
      height: 10px;
    }
  }
  &#{$accordion-open-class-name} {
    &:after {
      // border: 2px solid $white;
      // border-left: 0;
      // border-bottom: 0;
      top: 56%;
      transform: rotate(135deg) translateY(56%);
    }
  }
  &.narrow{
    width: auto;
    display: inline-block;
  }
}



.accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height $base-transition;
}

.accordion-contents-inner {
  background: $white;
  padding: 1em;
}
