@charset 'UTF-8';

$header-border-weight: 8px;



// header-wrapper
//
.header-wrapper {
  background: $white;
  border-top: $header-border-weight solid $base;
  position: relative;
  //position: fixed;
  width: 100%;
  @include media(lg){
    position: relative;
  }
}



// header-container
//
.header-container {
  @include make-container;
}



// header-inner
//
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @each $key, $value in $header-height {
    @include media($key, true){
      height: $value !important;
    }
  }
}

// header-inner に内包して使用する
//
// header-inner-left
.header-inner-left {
  flex: 1 1 0;
}

// header-inner-center
.header-inner-center {
  display: flex;
  justify-content: center;
  flex: 0 0 200px;
  @include media(md){
    flex: 0 0 284px;
  }
  @include media(lg){
    flex: 0 0 340px;
  }
  @include media(xl){
    flex: 0 0 434px;
  }
}

// header-inner-right
.header-inner-right {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-end;
}



// header brand
.header-brand {
  font-weight: 700;
  a {
    color: $gray-dark;
    &:hover {
      color: $gray-600;
    }
  }
}



// キーワード検索
.header-search {
  display: none;
  @include media(lg){
    display: block;
  }
}

.header-search-inner {
  display: flex;
}

.input-search {
  background: $white;
  border: 2px solid $base;
  border-radius: 4px 0 0 4px;
  box-shadow: none;
  color: $gray-dark;
  font-size: 1rem;
  line-height: 1;
  padding: .5rem 1rem;
  margin-right: 0;
  // プレースホルダー
  &::placeholder {
    font-size: .875rem;
    transition: color $base-transition;
  }
  &::-moz-placeholder {
    color: $gray-600;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $gray-600;
  }
  &::-webkit-input-placeholder {
    color: $gray-600;
  }
  &::-ms-expand {
    background: $white;
    border: 0;
  }
  // フォーカス
  &:focus {
    background: $white;
    border-color: $base;
    box-shadow: 0 0 6px 1px rgba( $base, .075 );
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
}

.btn-search {
  background-color: $base;
  background-image: set-svg($search, $white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 0 4px 4px 0;
  display: inline-block;
  width: 38px;
  //height: 38px;
}



// 電話番号
.header-telephone-wrapper {
  display: flex;
  align-items: center;
  @include media(lg){
    margin-top: 0.5em;
  }
  @include media(xl){
    margin-top: 1em;
  }
}

.telephone-icon {
  background-color: $white;
  background-image: set-svg($circle-phone, $base);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  width: 38px;
  height: 38px;
  @include media(lg){
    width: 26px;
    height: 26px;
  }
  @include media(xl){
    width: 32px;
    height: 32px;
  }
}

.telephone-number {
  display: none;
  @include media(lg){
    color: #374149;
    display: block;
    font-size: 1.5rem;
    line-height: 1;
    margin-left: .375rem;
    font-family: $notosans;
    transform: translateY(-0.125rem);
  }
  @include media(xl){
    font-size: 2rem;
  }
}



.header-icons-wrapper{
  display: none;
  @include media(lg){
    display: flex;
    margin-top: 0.5em;
    @include media(xl){
      margin-top: 1em;
    }
  }
}
.header-icon-item{
  display: block;
  width: 2.5em;
  height: 2.5em;
  opacity: 1;
  transition: opacity $base-transition;
  @include media(xl){
    width: 3em;
    height: 3em;
  }
  &:hover{
    opacity: 0.5;
  }
  & + .header-icon-item{
    margin-left: 1em;
  }
  img{
    width: 100%;
    height: 100%;
  }
}
.header-sns-wrapper{
  display: none;
  @include media(lg){
    display: flex;
    align-items: center;
  }
}
.header-sns-icon{
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.75em;
  @include media(xl){
    width: 2em;
    height: 2em;
  }
}
.header-sns-text{
  color: $base-text-color;
  font-size: 0.75em;
  @include media(xl){
    font-size: 0.875em;
  }
  &:hover{
    color: $base-text-color;
  }
  & + .header-sns-text{
    margin-left: 0.75em;
    padding-left: 0.75em;
    border-left: solid 1px $gray-400;
  }
}









.gnav-icons-wrapper{
  display: flex;
  justify-content: center;
  margin: 1em 0;
  @include media(lg){
    display: none;
  }
}
.gnav-icon-item{
  display: block;
  width: 3em;
  height: 3em;
  opacity: 1;
  transition: opacity $base-transition;
  &:hover{
    opacity: 0.5;
  }
  & + .gnav-icon-item{
    margin-left: 1em;
  }
  img{
    width: 100%;
    height: 100%;
  }
}
.gnav-sns-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  @include media(lg){
    display: none;
  }
}
.gnav-sns-icon{
  width: 2em;
  height: 2em;
  margin-right: 0.75em;
}
.gnav-sns-text{
  color: $base-text-color;
  font-size: 0.875em;
  &:hover{
    color: $base-text-color;
  }
  & + .gnav-sns-text{
    margin-left: 0.75em;
    padding-left: 0.75em;
    border-left: solid 1px $gray-500;
  }
}


