@charset 'UTF-8';

// ブログ用

.blog-visual-wrapper {
  aspect-ratio: 3/1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  padding: 1rem;
  position: relative;
}

.blog-visual-content {
  padding: .625rem;
  background-color: rgba($white, .8);
  box-shadow: 0px 0px 0px .625rem rgba($white, .8);
  @include media(md){
    padding: 1.5rem;
  }
  .blog-headline {
    font-size: 1.285714em;
    font-family: $notosans;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.0625em;
    display: inline-block;
    @include media(md){
      font-size: 2em;
    }
    span {
      display: block;
      font-size: .875em;
    }
  }
  &.nicu {
    border: 2px dashed #874a6c;
    .blog-headline {
      color: #874a6c;
      span {
        color: #6f802a;
      }
    }
  }
  &.reha {
    border: 2px dashed #118DF0;
    .blog-headline {
      color: #118DF0;
      span {
        color: #78B60C;
      }
    }
  }
  &.kango {
    border: 2px dashed #f5587b;
    .blog-headline {
      color: #f5587b;
      span {
        color: #F9931A;
      }
    }
  }
}


.multi-column-wrapper {
  display: flex;
  flex-direction: column;
  @include media(lg){
    flex-direction: row;
  }
}

.main-column {
  flex: 1;
  @include media(lg){
    padding-right: 2rem;
  }
}

.side-column {
  flex: 0 0 100%;
  margin-top: 4rem;
  @include media(lg){
    flex: 0 0 12rem;
    margin-top: 0;
  }
  @include media(xl){
    flex: 0 0 16rem;
  }
}



h1.blog-title {
  color: $gray-dark;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  position: relative;
  &::after {
    content: '';
    background: $second;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 14.4rem;
    height: .25rem;
  }
}

.entry-meta-container {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 1em;

  li {
    display: flex;
    align-items: center;
    font-size: .875rem;
    &:not(:last-child){
      margin-right: 1em;
    }
    &::before {
      content: '';
      @include svg-icon-default($person, $gray-500);
      margin-right: .25em;
    }
    &.entry-author {
      &::before {
        background-image: set-svg($person, $gray-500);
      }
    }
    &.entry-date {
      &::before {
        background-image: set-svg($clock, $gray-500);
      }
    }
    &.entry-category {
      &::before {
        background-image: set-svg($tag, $gray-500);
      }
    }
  }
}

.blog-article-container {
  p + p {
    margin-top: 1rem;
  }
}



// Side column
.blog-side-container {
  & + & {
    margin-top: 2rem;
  }
}

.blog-side-headline {
  background-color: #f9f6f2;
  color: $gray-dark;
  font-size: .875rem;
  font-weight: 700;
  margin-bottom: 1em;
  padding: .5em 1em .5em 1em;
  position: relative;
  &::before {
    background-color: $accent;
    content: '';
    display: inline-block;
    width: .5rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.blog-side-list {
  $side-list-gutter: .75em;
  margin: (- $side-list-gutter) 0;
  li {
    font-size: .875em;
    padding: $side-list-gutter 0;
    &:nth-of-type(n+2){
      border-top: 1px dotted $gray-500;
    }
  }
}

// アーカイブ
.archive-dropdown-container {
  position: relative;
}

.archive-dropdown {
  background: $white;
  border: 1px solid $gray-400;
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  padding: 12px 32px 12px 12px;
  outline: none;
  width: 100%;
}

.archive-dropdown-arrow {
  border: 2px solid $gray-500;
  border-right: 0;
  border-top: 0;
  display: block;
  margin-top: -3px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: rotate(-45deg) translateY(-50%);
  width: 8px;
  height: 8px;
}

// 検索
.searchform-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.searchform {
  background-color: $input-background-color;
  border: $border-animation-thickness solid $input-background-color;
  box-shadow: none;
  color: $gray-dark;
  flex: 1;
  font-size: $form-font-size;
  line-height: 1;
  padding: 1rem;
  transition: border-color $base-transition, background-color $base-transition;
  // プレースホルダー
  &::placeholder {
    color: $placeholder-color;
    font-size: 1rem;
    transition: color $base-transition;
  }
  &::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $placeholder-color;
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }
  &::-ms-expand {
    background: $white;
    border: 0;
  }
  // フォーカス
  &:focus {
    background-color: $white;
    border-color: $form-border-color;
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
}

.btn-searchform {
  background-color: $base;
  color: $white;
  font-weight: 700;
  padding: 0 1em;
  word-break: keep-all;
}



// ページネーション
.blog-pagenation-wrapper {
  display: block;
  margin-top: 2rem;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  font-size: 1rem;

  .nav-previous,
  .nav-next {
    a {
      border: 1px solid lighten($base, 60%);
      color: $base;
      display: inline-block;
      padding: 1rem;
      transition: background-color $base-transition, border-color $base-transition;
      &:hover {
        border-color: transparent;
        background-color: #f2f5f9;
      }
    }
  }
  .nav-previous {
    a {
      &::before {
        content: '';
        @include svg-icon-default($arrow-left, $base);
      }
    }
  }
  .nav-next {
    a {
      &::after {
        content: '';
        @include svg-icon-default($arrow, $base);
      }
    }
  }
}

// 「投稿ナビゲーション」の表示を非表示にする
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}
.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}

// 記事ページのページャー
.blog-pager-container {
  .prev, .next {
    display: block;
    a {
      border: 1px solid lighten($base, 60%);
      display: inline-block;
      padding: .5rem 1rem;
      transition: background-color $base-transition, border-color $base-transition;
      &:hover {
        border-color: transparent;
        background-color: #f2f5f9;
      }
    }
  }
  .prev {
    a {
      &::before {
        content: '';
        @include svg-icon-default($arrow-left, $base);
        margin-right: .5rem;
      }
    }
  }
  .next {
    margin-top: 1rem;
    text-align: right;
    a {
      &::after {
        content: '';
        @include svg-icon-default($arrow, $base);
        margin-left: .5rem;
      }
    }
  }
}
