@charset 'UTF-8';

*,
*::before,
*::after {
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

button:-moz-focusring,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
  font-family: inherit;
}

button,
select {
  text-transform: none;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}

input {
  &:autofill {
    transition: background-color 5000s ease-in-out 0s !important;
  }
  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s !important;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  border: 0;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

:focus:not(:focus-visible) {
  outline: none;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}

b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

i {
  font-style: italic;
  line-height: inherit;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
}
