@charset 'UTF-8';

.oneday-wrapper {
  margin: 0 auto;
  position: relative;
  max-width: 56rem;
  &::before {
    content: '';
    background: $third;
    border-radius: 2px;
    display: inline-block;
    position: absolute;
    left: 4em;
    top: 0;
    width: 2px;
    height: 100%;
  }
}



.oneday-content {
  background: $white;
  border-radius: .85714rem;
  box-shadow: $base-box-shadow;
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  & + & {
    margin-top: 1rem;
  }
}



.oneday-time {
  margin-right: 2rem;
  z-index: 1;
}

.time-item {
  background: $third;
  color: $white;
  width: 4rem;
  height: 4rem;
  display: inline-block;
  text-align: center;
  line-height: 4rem;
  border-radius: 50%;
  font-size: 1.25rem;
}



.oneday-inner {
  display: flex;
  align-items: center;
  flex-direction : column;
  align-items: flex-start;
  @include media(sm){
    flex-direction : row;
    align-items: center;
  }
}

.oneday-txt {
  width: 100%;
  margin-right: 2rem;
}

.oneday-heading {
  color: $third;
  display: block;
  font-size: 1.25rem;
  font-weight: 700;
}

.oneday-img {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-top: .5rem;
  img + img {
    margin-top: 1rem;
  }
  @include media(sm){
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
  @include media(lg){
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    margin-top: 0;
  }
}
