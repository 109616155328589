@charset 'UTF-8';

.btn-nav-wrapper {
  position: relative;
}

.btn-nav-container {
  display: flex;
  flex-flow: row wrap;
  margin: -.5rem;
}

.btn-nav-column {
  flex: 0 0 100%;
  padding: .5rem;
  @include media(sm){
    flex: 0 0 percentage(math.div(1, 2));
  }
  @include media(lg){
    flex: 0 0 percentage(math.div(1, 3));
  }
  @include media(xl){
    flex: 0 0 percentage(math.div(1, 4));
  }
}

.btn-nav-item{
  position: relative;
  height: 100%;
  overflow: hidden;
  //transition: ease .2s;
  background: $white;
  @include media(md){
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      background: $second;
      width: 100%;
      height: 100%;
      transition: transform .2s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top;
    }
    &:hover::before{
      transform-origin:left top;
      transform:scale(1, 1);
      transition: transform .4s cubic-bezier(0.8, 0, 0.2, 1) 0s;
    }
  }
}

// ボタン
.btn-nav {
  border: 2px solid $gray-dark;
  color: $gray-dark;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.125rem;
  padding: 1rem 1rem 1rem .7rem;
  position: relative;
  height: 100%;
  z-index: 1;
  @include media(md){
    font-size: 1.25rem;
  }
  // テキスト
  span.text {
    display: inline-block;
    flex: 1;
    line-height: 1.4;
    font-family: $notosans;
    font-weight: 700;
    letter-spacing: 0.0625em;
  }
  // 改行
  span.br-xl {
    display: inline;
    @include media(xl){
      display: block;
    }
  }
  // 矢印
  &::after {
    content: '';
    background-color: $second;
    background-image: set-svg($arrow, $white);
    background-size: 70%;
    background-position: center 30%;
    background-repeat: no-repeat;
    border-radius: 50%;
    display: inline-block;
    margin-left: .5rem;
    width: 33px;
    height: 33px;
  }
  // アイコン
  &::before {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: .7rem;
    width: 3rem;
    height: 3rem;
    transition: background-size $base-transition;
    background-size: contain, 0 0;
  }
  // ホバー
  &:hover {
    color: $gray-dark;
    @include media(md){
      &::before {
        animation-name: bgChange;
        animation-duration:0.4s;
        animation-fill-mode:forwards;
        background-size: 0 0, contain;
      }
    }
  }
  // 症状から受診する科を探す
  &.symptom {
    &::before {
      background-image: set-svg($question, $gray-dark),set-svg($question, $white);
    }
  }
  // 外来担当医表一覧
  &.schedule {
    &::before {
      background-image: set-svg($schedule, $gray-dark),set-svg($schedule, $white);
    }
  }
  // 休診代診一覧
  &.closure {
    &::before {
      background-image: set-svg($closure, $gray-dark),set-svg($closure, $white);
    }
  }
  // 分娩をご検討の方
  &.bunben {
    &::before {
      background-image: set-svg($bunben, $gray-dark),set-svg($bunben, $white);
    }
  }
  // 招待状をお持ちの方
  &.letter {
    &::before {
      background-image: set-svg($letter, $gray-dark),set-svg($letter, $white);
    }
  }
  // 当院への紹介をご検討の先生方
  &.hospital {
    &::before {
      background-image: set-svg($hospital, $gray-dark),set-svg($hospital, $white);
    }
  }
  &.houmon {
    &::before {
      background-image: set-svg($houmon, $gray-dark),set-svg($houmon, $white);
    }
  }
  &.nyuin {
    &::before {
      background-image: set-svg($nyuin, $gray-dark),set-svg($nyuin, $white);
    }
  }
}

