@charset 'UTF-8';

// font size
//
$label-font-size: .75em;



// label 上下の padding
//
$label-padding-y-xs: .425em;
$label-padding-y-sm: .425em;
$label-padding-y-md: .5em;
$label-padding-y-lg: .4em;
$label-padding-y-xl: .4em;

$label-padding-y: (
  xs: $label-padding-y-xs,
  sm: $label-padding-y-sm,
  md: $label-padding-y-md,
  lg: $label-padding-y-lg,
  xl: $label-padding-y-xl
) !default;



// label 左右の padding
//
$label-padding-x-xs: .6em;
$label-padding-x-sm: .6em;
$label-padding-x-md: .6em;
$label-padding-x-lg: .6em;
$label-padding-x-xl: .6em;

$label-padding-x: (
  xs: $label-padding-x-xs,
  sm: $label-padding-x-sm,
  md: $label-padding-x-md,
  lg: $label-padding-x-lg,
  xl: $label-padding-x-xl
) !default;



// 角丸
//
$label-border-radius: 0px !default;



// アウトライン時のボーダーの太さ
$label-border-width: 1px !default;



// background color
$label-background-colors: (
  'first':  $first,
  'second': $second,
  'third':  $third,
  'fourth': $fourth,
  'five':   $five,
  'accent': $accent
) !default;



// text color
$label-colors: (
  'first':  $white,
  'second': $white,
  'third':  $white,
  'fourth': $white,
  'five':   $white,
  'accent': $white
) !default;
