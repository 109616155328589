@charset 'UTF-8';

// お知らせや外来担当医表などの情報



.info-box-wrapper {
  position: relative;
}

.info-box-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: -2rem;
}

.info-box-news-column,
.info-box-info-column {
  flex: 0 0 100%;
  padding: 2rem;
  @include media(lg){
    flex: 0 0 50%;
  }
}

.info-box-info-column {
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  @include media(md){
    grid-template-columns: 1fr 1fr;
  }
  @include media(lg){
    grid-template-columns: 1fr;
  }
}

.inner-column-1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.inner-column-2 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  @include media(md){
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  @include media(lg){
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}



// お知らせ
ul.info-news-list {
  li {
    background-color: $white;
    box-shadow: $base-box-shadow-small;
    padding: 1rem;
    &:nth-of-type(n+2){
      margin-top: 1rem;
    }
  }

  .news-container {
    color: $gray-dark;
    display: flex;
    flex-flow: row wrap;
    transition: opacity $base-transition;
  }

  a[class~='news-container'] {
    &:hover {
      opacity: .5;
      color: $gray-dark;
    }
  }

  // 日付 & NEW
  .date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 100%;
    line-height: 1.6;
    margin-bottom: 1rem;
    @include media(lg){
      flex-direction: column;
      flex: 0 0 80px;
      margin-bottom: 0;
      margin-right: 1rem;
    }
    // 日付 & NEW
    span {
      display: inline-block;
      &.date {
        font-size: 1rem;
      }
      &.new-label {
        background-color: $accent;
        color: $white;
        font-size: .75rem;
        margin-left: 0.5rem;
        padding: 0 1.5rem;
        text-align: center;
        @include media(lg){
          margin-left: 0;
          margin-top: 0.25rem;
          padding: 0;
          width: 100%;
        }
      }
      &.label-patient {
        background-color: rgba(#2E73B7, 0.2);
        //color: $white;
        font-size: .75rem;
        margin-left: 0.5rem;
        padding: 0 1.5rem;
        text-align: center;
        @include media(lg){
          margin-left: 0;
          margin-top: 0.25rem;
          padding: 0;
          width: 100%;
        }
      }
      &.label-ordinary {
        background-color: rgba(#ECA04F, 0.3);
        //color: $white;
        font-size: .75rem;
        margin-left: 0.5rem;
        padding: 0 1.5rem;
        text-align: center;
        @include media(lg){
          margin-left: 0;
          margin-top: 0.25rem;
          padding: 0;
          width: 100%;
        }
      }
      &.label-participants {
        background-color: rgba(#92c674, 0.3);
        //color: $white;
        font-size: .75rem;
        margin-left: 0.5rem;
        padding: 0 1.5rem;
        text-align: center;
        @include media(lg){
          margin-left: 0;
          margin-top: 0.25rem;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
  // お知らせ本文
  .news-body {
    flex: 1;
    line-height: 1.8;
  }
}





// more
.more-container {
  margin-top: 1rem;
  text-align: right;
  .more-item {
    display: inline-block;
    padding-right: 2.25rem;
    position: relative;
    span{
      display: inline-block;
      width: 4rem;
      height: 0.928571rem;
      background-image: url('../../images/common/more.png');
      background-repeat: no-repeat;
      background-size: contain;
      transition: background $base-transition;
      @include media(lg){
        width: 5.625rem;
        height: 1.25rem;
      }
    }
    &::after {
      content: '';
      background-image: set-svg($arrow, $third);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      position: absolute;
      right: 0.25rem;
      top: -.25rem;
      width: 1.714285rem;
      height: 100%;
      transition: right $base-transition;
      @include media(lg){
        width: 1.875rem;
        height: 100%;
        top: -.375rem;
      }
    }
    @include media(lg){
      &:hover{
        span{
          background-image: url('../../images/common/more_hover.png');
        }
        &::after {
          right: 0;
        }
      }
    }
  }
}



// 外来受付・診療時間 & 面会時間
.info-container {
  background-color: $white;
  box-shadow: $base-box-shadow-small;
  padding: 1rem;
  @include media(lg){
    padding: 2.5rem;
  }
}

table.schedule-table {
  &.schedule {
    tr{
      border-bottom: solid 1px #999;
    }
    th {
      padding: 0 0 1rem 1rem;
      &:first-child{
        padding-left: 0;
      }
    }
    td {
      padding: 1rem 0 1rem 1rem;
      text-align: center;
      &:first-child{
        padding-left: 0;
      }
      &.noon {
        text-align: left;
      }
    }
  }
  &.visit {
    margin: -0.5rem;
    tr{
      @include media(xl){
        display: table-cell;
      }
    }
    th,td {
      padding: 0.5rem;
    }
  }
}

// ラベル
.schedule-table-label {
  background-color: $first;
  color: $white;
  display: block;
  font-weight: normal;
  line-height: 1;
  padding: .5rem;
  text-align: center;
  &.am, &.pm {
    color: $base-text-color;
    display: inline-block;
    padding: .5rem 1rem;
  }
  &.am {
    background-color:  rgba( $second, 0.3 );
  }
  &.pm {
    background-color: rgba( $third, 0.2 );
  }
}
